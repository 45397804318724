import {useState, useEffect} from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import PageRouter from "./PageRouter";
import Header from "./component/Header";
import SessionService from './api/SessionService';
import UserApi from './api/UserApi';
import { addDays, isAfter, isValid, parseISO } from 'date-fns';

// TODO favicon.ico with real logo. Maybe needs to be re-created ideally as SVG
function App() {
  document.title = 'Mini 360';
  let [showHeader, setShowHeader] = useState(true);
  
  useEffect(() => {
    const session = SessionService.getSession();
    // only update once per day
    if (SessionService.isSessionValid() && (isAfter(new Date(), addDays(parseISO(session.lastSeen), 1)) || !isValid(new Date(session.lastSeen)))) {
      SessionService.updateLastSeenDate();
      UserApi.updateSignIn(session.user.id)
      .then(val => console.log('Updated signIn', val))
      .catch(err => console.log('Error updating signIn', err));
    } else {
      console.log('not updating user db')
    }
  }, []);

  return (
    <Router>
      {showHeader && <Header/>}
      <PageRouter setShowHeader={setShowHeader}/>
      <Toaster gutter={3} position="top-center" reverseOrder={false}/>
    </Router>
  );
}

export default App;
