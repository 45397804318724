import styled from "styled-components";
import { Title, OutlineButton, ErrorMessageDiv, SubTitle } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NotificationService from '../../api/NotificationService';
import ProjectApi from '../../api/ProjectApi';
import fileDownload from 'js-file-download'

const Input = styled.input`
  width: 100%;
  min-width: 200px;
  padding: 10px;
  border-radius: var(--radius-1);
  margin-top: 3px;
  box-shadow: var(--shadow-1);
  border: 1px solid ${props => props.showError ? 'red' : 'rgba(0, 0, 0, 0.15)'}
`

export default function GenerateReportsModal(props) {
  let {close, projectId, selectedParticipants, setSelectedRows, setSelectedParticipants, supportEmail, defaultEmail} = props;
  const formik = useFormik({
    initialValues: {
      email: defaultEmail,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be valid email')
        .required('Required'),
    }),
    onSubmit: values => {
      generateReports(false, values.email);
    },
  });

  const generateReports = function(downloadReports, email) {
    let promises = [];
    if (downloadReports) {
      for (let i = 0; i < selectedParticipants.length; i++) {
        promises.push(ProjectApi.generateReports(projectId, [selectedParticipants[i]], downloadReports));
      }
    } else {
      promises.push(ProjectApi.generateReports(projectId, selectedParticipants, downloadReports, email));
    }
    Promise.all(promises).then(res => {
      if (downloadReports) {
        for (let i = 0; i < selectedParticipants.length; i++) {
          fileDownload(res[i].body.content, res[i].body.filename);
        }
      }
      NotificationService.success("Successfully generated projects, check your inbox for reports");
      setSelectedRows([...Array(selectedParticipants.length)].map(() => []));
      setSelectedParticipants([...Array(selectedParticipants.length)].map(() => []));
      close();
    }).catch(error => {
      console.error('Failed to generate reports', error);
      NotificationService.error(`Failed to generate reports. Try again later, if issues persists please reach out to ${supportEmail}.`)
    });
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "top", borderBottom: "1px solid var(--dark-color)", marginBottom: "10px"}}>
        <div>
          <Title>Generate Reports</Title>
          <SubTitle>You can download the reports to your machine or have them emailed to you directly.</SubTitle>
        </div>
        <FontAwesomeIcon className="icon-hover" icon={faCircleXmark} onClick={close} style={{alignSelf: 'start'}}/>
      </div>
      <div style={{marginBottom: "10px", display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr auto", gap: '10px', justifyItems: 'center'}}>
        <div> 
            <Input name="email" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} placeholder='andrew@weeks.com'/>
            {formik.touched.email && formik.errors.email && <ErrorMessageDiv> {formik.errors.email} </ErrorMessageDiv>}
        </div>
        <div style={{gridColumn: '1', gridRow: '2'}}>
          <OutlineButton type="submit">Email</OutlineButton> 
        </div>
        <div style={{gridColumn: '2', gridRow: '2'}}>
          <OutlineButton type="button" onClick={() => generateReports(true)}>Download Report</OutlineButton> 
        </div>
      </div>
    </form>
  );
}