import Api from "./Api";

const LeadService = {
  register: async function (body) {
    const response = await Api.post(`/register/lead`, {
      name: body.name,
      email: body.email,
      message: body.message
    });
    return response.data;
  }
};

export default LeadService;
