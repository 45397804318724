import {Title, PreTitle, PageContainer, OutlineButton, Card, CardBody, CardTitle, CardSubtitle} from "../UI.js"
import { useNavigate } from "react-router-dom";


export default function ProjectSummaryChart(props) {
  let navigate = useNavigate();
  
  let dashboardData = props.dashboardData;

  return (
    <PageContainer style={{  gridTemplateColumns: "1fr 1fr", justifyItems: "space-around", padding: "1rem", marginBottom: "20px"}}>
      <div style={{marginBottom: "10px"}}>
        <PreTitle>View</PreTitle>
        <Title> Summary </Title>
      </div>
      <div style={{justifySelf: "end"}}>
        <OutlineButton onClick={() => navigate(`/create`)}> New project </OutlineButton>
      </div>
      <div style={{gridColumn: "1/-1", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: "1rem"}}>
        <Card>
          <CardBody>
            <CardTitle>{dashboardData.activeProjects}</CardTitle>
            <CardSubtitle>Project Active</CardSubtitle>
          </CardBody>
        </Card>
        <Card>
            <CardBody>
              <CardTitle className="text-small">{dashboardData.participantsComplete}</CardTitle>
              <CardSubtitle>Complete Participants</CardSubtitle>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
              <CardTitle className="text-yellow text-small">{dashboardData.participantsPending}</CardTitle>
              <CardSubtitle>Pending Participants</CardSubtitle>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
              <CardTitle className="text-green text-small">{dashboardData.reportsComplete}</CardTitle>
              <CardSubtitle>Reports ready to send</CardSubtitle>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
              <CardTitle className="text-small">{dashboardData.observersComplete}</CardTitle>
              <CardSubtitle>Complete Observers</CardSubtitle>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
              <CardTitle className="text-yellow text-small">{dashboardData.observersPending}</CardTitle>
              <CardSubtitle>Pending Observers</CardSubtitle>
            </CardBody>
        </Card>

      </div>
    </PageContainer>
  );
}
