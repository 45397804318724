import ContactEntryForm from "./ContactEntryForm";
import NotificationService from "../../api/NotificationService";
import {ParticipantSchema} from "../../util/SchemaUtils";
import {Form, Formik} from "formik";
import {PreTitle, ProjectCard} from "../UI";

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export default function ProjectCreateParticipants(props) {
    const {updateBodyField, setNextCallback, setActive, participantFormValues, setParticipantFormValues} = props;

    async function handleSubmit(_values) {
        console.log('participants', _values);
        try {
            updateBodyField('participants', _values.participants);
            setParticipantFormValues(_values);
            setActive(a => a + 1);
        } catch (e) {
            console.error('Failed to save participants', e);
            // TODO maybe a mail-to?
            NotificationService.error(`Something went wrong. If issues persists, please reach out to ${SUPPORT_EMAIL}.`);
        }
    }

    return (
        <Formik key="participants" initialValues={participantFormValues} validationSchema={ParticipantSchema}
                onSubmit={async _values => handleSubmit(_values)}>
            {(props) => {
                setNextCallback(props.handleSubmit);
                return (<Form><ProjectCard>
                    <PreTitle style={{marginBottom: "5px"}}>Participants</PreTitle>
                    <ContactEntryForm values={props.values} errors={props.errors} fieldName="participants"
                                      fieldLabel="participant" setFieldValue={props.setFieldValue}/>
                </ProjectCard></Form>)
            }}
        </Formik>
    );
}
