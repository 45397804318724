import * as Yup from 'yup';
import {isAfter, isValid, parseISO, startOfToday} from 'date-fns'

Yup.addMethod(Yup.string, 'isAfter', function(date, errorMessage) {
    return this.test('test-name', date, function(value) {
        const { path, createError } = this;
        return isAfter(new Date(value), date) || createError({path, message: errorMessage});
    });
});

Yup.addMethod(Yup.string, 'isValid', function(errorMessage) {
    return this.test('test-name', function(value) {
        const { path, createError } = this;
        return !value || isValid(parseISO(value)) || createError({path, message: errorMessage});
    });
});

Yup.addMethod(Yup.string, 'isAfterField', function(field, errorMessage, observerInitial) {
    return this.test('test-name', field, function(value) {
      const { path, createError } = this;
      if (observerInitial) {
        if (this.parent['sendObserverEmailImmediately']) return true;
      }
        return isAfter(new Date(value), new Date(this.parent[field])) || createError({path, message: errorMessage});
    });
});

Yup.addMethod(Yup.string, 'conditionallyRequired', function(field, requiredValue, errorMessage) {
  return this.test('test-name', field, function(value) {
      const { path, createError } = this;
      return !!value || this.parent[field] !== requiredValue || createError({path, message: errorMessage});
  });
});

export const DateSchema = Yup.object().shape({
feedbackParticipantInitialDate: Yup.string()
  .required('Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfter(startOfToday(), 'Date must be in the future!'),
feedbackParticipantReminderDate: Yup.string()
  .required('Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfterField(`feedbackParticipantInitialDate`, 'Date must be after participant initial'),
feedbackParticipantDeadlineDate: Yup.string()
  .required('Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfterField(`feedbackParticipantReminderDate`, 'Date must be after participant reminder'),
feedbackObserverInitialDate: Yup.string()
  .conditionallyRequired('sendObserverEmailImmediately', false, 'Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfterField(`feedbackParticipantDeadlineDate`, 'Date must be after participant deadline', true),
feedbackObserverReminderDate: Yup.string()
  .required('Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfterField(`feedbackObserverInitialDate`, 'Date must be after observer initial', true),
feedbackObserverDeadlineDate: Yup.string()
  .required('Required')
  .isValid('Date must be valid')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must in format "YYYY-MM-DD"')
  .isAfterField(`feedbackObserverReminderDate`, 'Date must be after observer reminder'),
});

export const ParticipantSchema = Yup.object({
  participants: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
      lastName: Yup.string()
        .min(2, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
      email: Yup.string()
        .required('Required')
        .email('Email is not valid')
  })).min(1, 'Minimum 1 participant')
});

export const ObserverSchema = Yup.object({
  observers: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
      lastName: Yup.string()
        .min(2, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
      email: Yup.string()
        .required('Required')
        .email('Email is not valid')
  }))
  // .min(3, 'Minimum 3 observers')
  //    .max(10, 'Maximum 10 observers')
  // TODO come back to find a way to do this dynamically
});

export const QuestionSchema = Yup.object({
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string()
        .min(2, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
      type: Yup.string()
        .required('Required')
  })).min(1, 'Minimum 1 question')
});

export const AnswerSchema = Yup.object({
  answers: Yup.array().of(
    Yup.object().shape({
      answer: Yup.string()
        .min(5, 'Too short!')
        .max(1000, 'Too long!')
        .required('Required'),
  }))
});

export const SplashSchema = Yup.object({
  name: Yup.string()
  .min(5, 'Too short!')
  .max(250, 'Too long!')
  .required('Required'),
  email: Yup.string()
  .required('Required')
  .email('Email must be valid email'),
  message: Yup.string()
  .min(30, 'Minimum length 30 characters')
  .required('Required')
});

export const DeleteMyDataSchema = Yup.object({
    name: Yup.string()
        .min(5, 'Too short!')
        .max(250, 'Too long!')
        .required('Required'),
    email: Yup.string()
        .required('Required')
        .email('Email must be valid email'),
    message: Yup.string()
});

export const ProjectSetupSchema = Yup.object({
  projectName: Yup.string()
  .min(2, 'Too short!')
  .max(150, 'Too long!')
  .required('Required'),
  allowParticipantLateEntry: Yup.boolean(),
  sendObserverEmailImmediately: Yup.boolean(),
  minimumNumberOfObservers: Yup.number()
    .required()
    .min(1, 'Minimum 1 observer'),
  maximumNumberOfObservers: Yup.number()
    .required()
    .min(1, 'Minimum 1 observer')
    .moreThan(Yup.ref("minimumNumberOfObservers"), 'Maximum must be greater than minimum'),
  projectLeadName: Yup.string()
    .required('Required')
    .min(2, 'Too short')
    .max(250, 'Too long'),
  supportName: Yup.string()
    .required('Required')
    .min(2, 'Too short')
    .max(250, 'Too long'),
  supportEmail: Yup.string()
    .required('Required')
    .email('Must be valid email'),
  statusReportEmail: Yup.string()
    .required('Required')
    .email('Must be valid email'),
  finalReportEmail: Yup.string()
  .required('Required')
  .email('Must be valid email'),
  includeObserversInReport: Yup.boolean(),
  leftLogoId: Yup.string(),
  rightLogoId: Yup.string(),
});