import React, {useEffect} from 'react';
import SplashComponent from "../component/SplashComponent";
import NotificationService from '../api/NotificationService.js';
import {useLocation} from "react-router-dom";


export default function HomePage() {
    const {state} = useLocation();

    useEffect(() => {
        if (!!state && state.loggedOut) {
            NotificationService.warning('You must be logged in to view this page');
        }
    }, [state]);

    return (
        <SplashComponent/>
    );
}
