import styled from "styled-components";
import {ErrorMessageDiv, InputTable, TableIcon, AddNewRowButton} from "../UI.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import { Field, FieldArray, ErrorMessage } from 'formik';
import NotificationService from '../../api/NotificationService';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const EmailSentDiv = styled.div`
  font-size: 0.6rem;
  color: var(--faded-text-color);
  line-height: var(--font-lineheight-0);
`

export default function ContactEntryForm(props) {
  const fieldName = props.fieldName;
  const fieldLabel = props.fieldLabel;
  const values = props.values;
  const errors = props.errors;
  const max = props.max || 1000;
  const min = props.min || 1;
  return (
    <InputTable>
      <thead>
        <tr>
          <th style={{width: "5%"}}> # </th>
          <th style={{width: "25%"}}> First Name </th>
          <th style={{width: "25%"}}> Last Name </th>
          <th style={{width: "40%"}}> Email </th>
          <th style={{width: "5%"}}> </th>
        </tr>
      </thead>
      <tbody>
      <FieldArray name={fieldName}
          render={arrayHelpers => (
              <>
              {values[fieldName] && values[fieldName].length > 0 ? (
                   values[fieldName].map((r, index) => (
                     <tr key={index}>
                       <td style={{textAlign: "center"}} data-placeholder={`${fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1)} #`}>{index + 1}</td>
                        <td>
                          <Field name={`${fieldName}.${index}.firstName`} placeholder="First name" disabled={!!r.emailSent} onChange={(event) => props.setFieldValue(event.target.name, event.target.value.trim())}/>
                          <ErrorMessageDiv><ErrorMessage name={`${fieldName}.${index}.firstName`}/></ErrorMessageDiv>
                         </td>
                         <td>
                           <Field name={`${fieldName}.${index}.lastName`} placeholder="Last name" disabled={!!r.emailSent} onChange={(event) => props.setFieldValue(event.target.name, event.target.value.trim())}/>
                           <ErrorMessageDiv><ErrorMessage name={`${fieldName}.${index}.lastName`}/></ErrorMessageDiv>
                          </td>
                          <td>
                            <Field name={`${fieldName}.${index}.email`} placeholder="Email address" disabled={!!r.emailSent} onChange={(event) => props.setFieldValue(event.target.name, event.target.value.trim())}/>
                            <ErrorMessageDiv><ErrorMessage name={`${fieldName}.${index}.email`}/></ErrorMessageDiv>
                           </td>
                           <td> 
                            {!r.emailSent && <TableIcon onClick={() => {
                             if (arrayHelpers.form.values[fieldName].length === min) {
                                NotificationService.error(`Minimum ${min}`);
                              } else {
                                arrayHelpers.remove(index)
                              }
                           }} className="red-icon-row center">
                                <Tippy content={`Delete ${fieldLabel}`}><span><FontAwesomeIcon icon={faTrashCan} className="small-icon red-icon"/> </span></Tippy>
                              </TableIcon>}
                              {!!r.emailSent && <EmailSentDiv>Email Already Sent</EmailSentDiv>}
                              </td>
                      </tr>))) : null}
                      {arrayHelpers.form.values[fieldName].length < max && <tr>
                        <td colSpan="5" onClick={() => {arrayHelpers.push(createEmptyContact())}}>
                          <AddNewRowButton> <FontAwesomeIcon icon={faCirclePlus} className="small-icon"/> Add new {fieldLabel}</AddNewRowButton>
                        </td>
                      </tr>}
                  {typeof errors[fieldName] === 'string' &&  <tr>
                    <td colSpan="5"> <ErrorMessageDiv style={{textAlign: "center"}}><ErrorMessage name={fieldName}/></ErrorMessageDiv></td>
                  </tr>}
              </>
          )}/>
      </tbody>
    </InputTable>
  );
}

export const createEmptyContact = function() {
  return { id: null, firstName: '', lastName: '', email: ''};
}