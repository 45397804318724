import Api from "./Api";

const ParticipantApi = {
  findById: async function (id) {
    const response = await Api.get(`/projects/participants/${id}`);
    return response.data;
  },

  saveParticipantsForProject: async function (projectId, participants) {
    const response = await Api.post(`/projects/${projectId}/participants`, participants);
    return response.data;
  }
};

export default ParticipantApi;
