import axios from "axios";
import SessionService from "./SessionService";

const API = process.env.REACT_APP_API;
const Api = {
  get: async function (uri, params) {
    return await axios.get(`${API}${uri}`, getConfig(params));
  },

  post: async function (uri, data) {
    return await axios.post(`${API}${uri}`, data, getConfig());
  },

  put: async function (uri, data) {
    return await axios.put(`${API}${uri}`, data, getConfig());
  },

  delete: async function (uri) {
    return await axios.delete(`${API}${uri}`, getConfig());
  }
};

function getConfig(params = {}) {
  const session = SessionService.getSession();
  if (!session) {
    return {params: params};
  }

  return {
    headers: {
      "Session-Id": session.id
    },
    params: params
  };
}

export default Api;
