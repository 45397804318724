import {useState, useEffect} from 'react';
import {PageContainer, AlreadySubmittedComponent, JustSubmittedComponent, ErrorComponent} from "../component/UI";
import NotificationService from '../api/NotificationService';
import ObserverApi from "../api/ObserverApi";
import {useParams} from 'react-router-dom';
import AnswerEntryContent from '../component/projects/AnswerEntryContent';


export default function ObserverResponsePage(props) {
    let {setShowHeader} = props;
    let [observer, setObserver] = useState({});
    let [participant, setParticipant] = useState({});
    let [state, setState] = useState({
        loaded: false,
        // TODO
        tooLate: false,
        justSubmitted: false,
        submitted: false,
        error: false,
    });
    let [initialValues, setInitialValues] = useState({
        answers: [
            {questionId: '', type: '', question: '', answer: ''}]
    });

    const {id} = useParams();

    useEffect(() => {
        setShowHeader(false);
    }, [setShowHeader]);

    useEffect(() => {
        ObserverApi.findById(id, true).then(response => {
            const _observer = response.observer;
            setObserver(_observer);
            setInitialValues({
                answers: response.questions.map(question => {
                    return {questionId: question.id, question: question.question, type: question.type, answer: ''}
                })
            });

            setState(s => {
                return {
                    ...s,
                    loaded: true,
                    submitted: !!_observer.answers && _observer.answers.length > 0,
                    supportName: response.project.supportName,
                    supportEmail: response.project.supportEmail,
                }
            });
            setParticipant(response.participant);
            // check if this works
        }).catch(e => {
            setState(s => {
                return {...s, error: true}
            });
            console.error(e);
        });
    }, [id, setState]);

    async function submitForm(values) {
        console.log('saveAndSubmit', values);
        // TODOAWS test
        try {
            await ObserverApi.saveAnswersForObservers(observer.id, values);
            NotificationService.success("Successfully saved responses.");
            setObserver({...observer});
            setState({
                ...state,
                justSubmitted: true
            });
        } catch (e) {
            console.error('Failed to save observer answers', e);
            NotificationService.error(`Failed to save responses. Please try again later, if issues persists please reach out to ${state.supportName}.`)
        }
    }

    return (
        <div style={{marginTop: "15px"}}>
            <LoadComponent observer={observer} participant={participant} state={state} initialValues={initialValues}
                           submitForm={submitForm}/>
        </div>
    );
}

function LoadComponent(props) {
    const {observer, participant, state, initialValues, submitForm} = props;

    if (state.error) {
        return <PageContainer style={{margin: "0 auto"}}>
            <ErrorComponent title={'Oops. Something went wrong'}
                            subtitle={'Sorry, the page you are looking for is temporarily unavailable.'}
                            supportName={state.supportName} supportEmail={state.supportEmail}></ErrorComponent>
        </PageContainer>
    }
    if (state.justSubmitted) {
        return <PageContainer style={{margin: "0 auto"}}>
            <JustSubmittedComponent firstName={observer.firstName} lastName={observer.lastName} type='observer'
                                    supportName={state.supportName} supportEmail={state.supportEmail}/>
        </PageContainer>
    }

    // TODO implement
    // if (state.tooLate) {
    //   return <TooLateComponent firstName={participant.firstName} lastName={participant.lastName} type='participant' supportName={state.supportName} supportEmail={state.supportEmail}/>
    // }

    if (state.submitted) {
        return <PageContainer style={{margin: "0 auto"}}>
            <AlreadySubmittedComponent firstName={observer.firstName} lastName={observer.lastName}
                                       supportName={state.supportName} supportEmail={state.supportEmail}/>
        </PageContainer>
    }
    if (state.loaded) {
        return <AnswerEntryContent observer={observer} participant={participant} initialValues={initialValues}
                                   submitForm={submitForm} supportEmail={state.supportEmail}/>
    }
    return <></>
}