import styled from "styled-components";
import React from 'react';
import {PreTitle} from "../UI.js";
import LogoApi from '../../api/LogoApi';


const SummaryTable = styled.table`
  width: clamp(540px, 70vw, 90vw);
  max-width: 580px;

  background: var(--secondary-color);
  text-align: left;
  border-collapse: collapse;
  box-shadow: var(--shadow-2);
  thead, tfoot {
    background: var(--gray-5);
  }
  th {
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
  }
  th,td {
    padding: .8rem;
    font-size: 0.8rem;
  }
  tr {
    border-bottom: 1px solid var(--gray-4);
  }

  .header {
    font-size: 0.5rem;
    font-weight: 300;

    td {
      padding: 0.2rem;
      padding-left: 1.5rem;
    }
  }
`

const ProjectCard = styled.div`
  width: clamp(540px, 80vw, 90vw);
  max-width: 600px;

  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.16);
  padding: 1rem;
  display: grid;
  justify-content: center;
`

export default function ProjectCustomizationSummary(props) {
  const formValues = {props}

  return <ProjectCard>
    <PreTitle style={{marginBottom: "5px"}}>Customization</PreTitle>
    <SummaryTable>
      <thead>
        <tr>
          <th style={{width: "50%"}}> Setting </th>
          <th style={{width: "50%"}}> Value </th>
        </tr>
      </thead>
      <tbody>
        <tr className="header">
          <td colSpan={2}>Admin</td>
        </tr>
        <tr> 
          <td>Project Lead Name </td>
          <td>{formValues.projectLeadName}</td>
        </tr>
        <tr> 
          <td>Support Name </td>
          <td>{formValues.supportName}</td>
        </tr>
        <tr> 
          <td>Support Email </td>
          <td>{formValues.supportEmail}</td>
        </tr>
        <tr> 
          <td>Status Report Email </td>
          <td>{formValues.statusReportEmail}</td>
        </tr>
        <tr> 
          <td>Final Report Email </td>
          <td>{formValues.finalReportEmail}</td>
          </tr>
        <tr className="header">
          <td colSpan={2}>Participant</td>
        </tr>
        <tr>
          <td colSpan={2}>Participants <b>are{formValues.allowParticipantLateEntry ? '' : ' not'}</b> able to add observers after deadline.</td>
        </tr>
        <tr>
          <td colSpan={2}>Observers <b>will{formValues.sendObserverEmailImmediately ? '' : ' not'}</b> be notified immediately after selected by participant(s).</td>
        </tr>
        <tr>
          <td colSpan={2}>Participants must choose between <b>{formValues.minimumNumberOfObservers}</b> and <b>{formValues.maximumNumberOfObservers}</b> observers.</td>
        </tr>
        <tr className="header">
          <td colSpan={2}>Feedback Report</td>
        </tr>
        <tr>
          <td colSpan={2}>Observers who gave feedback <b>are{formValues.includeObserversInReport ? '' : ' not'}</b> named in the final report.</td>
        </tr>
        {/*TOD feedbackReportPagePerQuestion*/}
        <tr>
          <td colSpan={2}>Final report questions <b>will{formValues.feedbackReportPagePerQuestion ? '' : ' not'}</b> be on their own page.</td>
        </tr>
        <tr>
          <td>Left Logo</td>
          <td>{!!LogoApi.getIndex(formValues.leftLogoId) ? LogoApi.getIndex(formValues.leftLogoId).name : 'No logo chosen'}</td>
        </tr>
        <tr>
          <td>Right Logo</td>
          <td>{!!LogoApi.getIndex(formValues.rightLogoId) ? LogoApi.getIndex(formValues.rightLogoId).name : 'No logo chosen'}</td>
        </tr>
      </tbody>
    </SummaryTable>
  </ProjectCard>
}
