import toast from 'react-hot-toast';

const NotificationService = {

  error: function (message) {
    toast.error(message, {
      style: {
        border: '1px solid #713200',
        background: '#FFFAEE',
        padding: '16px',
        color: '#713200',
      },
      iconTheme: {
        primary: '#713200',
        secondary: '#FFFAEE',
      },
    });
  },
  warning: function (message) {
    toast(message, {
      style: {
        border: '1px solid #713200',
        background: '#FFFAEE',
        padding: '16px',
        color: '#713200',
      },
      iconTheme: {
        primary: '#713200',
        secondary: '#FFFAEE',
      },
      icon: '⚠️'
    });
  },
  success: function (message) {
    toast.success(message, {
      style: {
        border: '1px solid #386641',
        padding: '16px',
        background: '#FFFAEE',
        color: '#386641',
      },
      iconTheme: {
        primary: '#386641',
        secondary: '#FFFAEE',
      },
    });
  }
};

export default NotificationService;
