const SessionService = {

  getSession: function() {
    return JSON.parse(localStorage.getItem('session')) || {};
  },

  createSession: function(user, session) {
    localStorage.setItem('session', JSON.stringify({
      id: session.id,
      expiresAt: session.expiresAt,
      user: user,
      lastSeen: new Date()
    }))
  },

  updateLastSeenDate: function() {
    let session = SessionService.getSession();
    if (!session.session) {
      return
    }
    SessionService.createSession(session.user, session.session);
  },

  isSessionValid: function(session = SessionService.getSession()) {
    return !!session.id && new Date(session.expiresAt) > new Date();
  },

  invalidateSession: function() {
    localStorage.clear();
  }
};

export default SessionService;
