import {useEffect, useState} from 'react';
import styled from "styled-components";
import {CheckboxField, CustomizationInput, NumberInput, PreTitle, ProjectCard, SelectInput} from "../UI.js";
import LogoApi from '../../api/LogoApi';
import {Form, Formik} from "formik";
import {ProjectSetupSchema} from "../../util/SchemaUtils";
import NotificationService from "../../api/NotificationService";

const CustomizationFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 10px;

  select {
    width: 90%;
    padding: 0.6rem 0.2rem;
    font-size: 0.7rem;
    border-radius: var(--radius-1);
    border: 1px solid #ced4da;;
  }

  input {
    font-size: 0.8rem;
  }
`

const CustomizationFieldset = styled.fieldset`
  margin-left: -12px;
  grid-column: 1/-1;
  border-right: ${props => props.showAdvanced ? 'default' : '0px'};
  border-left: ${props => props.showAdvanced ? 'default' : '0px'};
  border-bottom: ${props => props.showAdvanced ? 'default' : '0px'};
  legend {
    z-index: 1;
    font-size: 0.6rem;
  }
`

const CustomizationInner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 0;
  height: ${props => props.showAdvanced ? 'initial' : '0'};
  
  opacity: ${props => props.showAdvanced ? '1' : '0'};
  z-index: -1;
  transform: translateY(${props => props.showAdvanced ? '0' : '-9999px'});
  transition: opacity ease 1s;
`

const HorizontalLine = styled.div`
  width: 100%;
  margin: 0.4rem 0;
  border-bottom: 1px solid var(--secondary-color);
`

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export default function ProjectCreateCustomization(props) {
  const {setNextCallback, updateBodyField, setActive, projectSetupFormValues, setProjectSetupFormValues} = props;
  let [logos, setLogos] = useState([{value: '', displayValue: 'No Logo'}]);
  let [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    LogoApi.getAll()
        .then(logos => setLogos([{value: '', displayValue: 'No Logo'}, ...logos.map(logo => {return {value: logo.id, displayValue: logo.name}})]))
        .catch(err => console.error('Failed to get logos', err));
  }, []);

  async function handleSubmit(_values) {
    console.log('project', _values);
    try {
      _values.sendObserverEmailImmediately = _values.sendObserverEmailImmediately === 'true';
      updateBodyField('project', _values);
   setProjectSetupFormValues(_values)
      setActive(a => a+1);
    } catch (e) {
      console.error('Failed to save questions', e);
      // TODO maybe a mail-to?
      NotificationService.error(`Something went wrong. If issues persists, please reach out to ${SUPPORT_EMAIL}.`);
    }
  }
  return (
      <Formik key="customizations" initialValues={projectSetupFormValues} validationSchema={ProjectSetupSchema}
              onSubmit={async _values => handleSubmit(_values)}>
        {(props) => { setNextCallback(props.handleSubmit);
          return (<Form>
            <ProjectCard style={{marginBottom: "1.5rem"}}>
              <CustomizationFormContainer>
                <PreTitle className="grid-full-row">Project Information</PreTitle>
                <CustomizationInput label={"Project Name:"} subtitle={'Reference project name that will appear in emails to users and on final report.'} input={{name: "projectName", placeholder: "Project Name", errors: props.errors, touched: props.touched, onChange: props.onChange}} className={"grid-full-row"}/>
                <HorizontalLine className="grid-full-row"/>
                <PreTitle className="grid-full-row">Feedback Report Options</PreTitle>
                <SelectInput id={'leftLogo'} name={'leftLogoId'} values={props.values} errors={props.errors} label="Left Logo" subtitle="Logo to appear on top left of every page." options={logos}/>
                <SelectInput id={'rightLogo'} name={'rightLogoId'} values={props.values} errors={props.errors} label="Right Logo" subtitle="Logo to appear on top right of every page." options={logos}/>
                <HorizontalLine className="grid-full-row"/>
                <CustomizationFieldset showAdvanced={showAdvanced}>
                  <legend onClick={() => setShowAdvanced(!showAdvanced) }> {showAdvanced ? 'Hide' : 'Show'} Advanced Configuration</legend>
                  <CustomizationInner showAdvanced={showAdvanced}>
                    <PreTitle className="grid-full-row">Contact Information</PreTitle>
                    <CustomizationInput label={"Project Lead:"} subtitle={'Name that will be presented to users if issues arise and in signature of emails (e.g. from Mini360 Support on behlaf of <name>)'} input={{name: "projectLeadName", placeholder: 'Andrew Weeks', errors: props.errors, touched: props.touched}} />
                    <CustomizationInput label={"Support Name:"} subtitle={'Name that will be presented to users to participants in case of issues, concerns or questions. Present in all emails.'} input={{name: "supportName", placeholder: 'Andrew Weeks', errors: props.errors, touched: props.touched}} />
                    <CustomizationInput label={"Support Email:"} subtitle={'Email that will be presented to users to participants in case of issues, concerns or questions. Present in all emails.'} input={{name: "supportEmail", placeholder: 'andrew@weeks.com', errors: props.errors, touched: props.touched}} />
                    <CustomizationInput label={"Status Updates:"} subtitle={'Weekly status reports about participant / observers.'} input={{name: "statusReportEmail", placeholder: 'andrew@weeks.com', errors: props.errors, touched: props.touched}} />
                    <CustomizationInput label={"Final Report:"} subtitle={'Final reports will be sent to this email'} input={{name: "finalReportEmail", placeholder: 'andrew@weeks.com', errors: props.errors, touched: props.touched}} />
                    <HorizontalLine className="grid-full-row"/>
                    <PreTitle className="grid-full-row">Participant Options</PreTitle>
                    <SelectInput id={'sendObserverEmailImmediately'} name={'sendObserverEmailImmediately'} values={props.values} errors={props.errors} label="Observer Email sending" subtitle="Initial message to observers can be sent when the participant selects them. Alternatively you can select a date and all observers (from all participants) will receive the message at the same time." options={[{value: false, displayValue: 'Send on date'}, {value:true, displayValue: 'Immediately on submit'}]}/>
                    <CheckboxField id={'allowParticipantLateEntry'} name={'allowParticipantLateEntry'} values={props.values} errors={props.errors} label="Prevent Participants from adding observers after deadline" subtitle="Allow participants to add observers after the participant deadline. They will be able to keep adding them until then if not selected."></CheckboxField>
                    <HorizontalLine className="grid-full-row"/>
                    <PreTitle className="grid-full-row">Observer Limits</PreTitle>
                    <NumberInput label={'Minimum number of observers'} input={{id: 'minimumNumberOfObservers', name: 'minimumNumberOfObservers', values: props.values, errors: props.errors, touched: props.touched}}/>
                    <NumberInput label={'Maximum number of observers'} input={{id: 'maximumNumberOfObservers', name: 'maximumNumberOfObservers', values: props.values, errors: props.errors, touched: props.touched}}/>
                    <HorizontalLine className="grid-full-row"/>
                    <PreTitle className="grid-full-row">Feedback Report Options</PreTitle>
                    <CheckboxField id={'includeObserversInReport'} name={'includeObserversInReport'} values={props.values} errors={props.errors} label="Named observers" subtitle="Include the names of observers who gave feedback in report (they will not be associated with feedback, given only as a summary)"></CheckboxField>
                    <CheckboxField id={'feedbackReportPagePerQuestion'} name={'feedbackReportPagePerQuestion'} values={props.values} errors={props.errors} label="Start each question on a new page" subtitle="If checked the report will create a new page for each question in the final report. Otherwise the questions will be have a small gap but be immediately after each other."></CheckboxField>
                  </CustomizationInner>
                </CustomizationFieldset >
              </CustomizationFormContainer>
            </ProjectCard>
          </Form>)}}
      </Formik>
  );
}
