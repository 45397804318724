import styled from "styled-components";
import Day from './Day.js';
import HeaderDay from './HeaderDay.js';
import {format, isAfter, isBefore, isSunday, isSaturday, previousSunday, nextSaturday, isSameDay, isValid, addDays, differenceInDays, parseISO} from 'date-fns';
import {useEffect, useState} from 'react';

export const CalendarContainer = styled.div`
  --size: 1.5rem;
  --bg-color: inherit;
  display: grid;
  grid-template-columns: auto auto;
  background: var(--gray-0);
  padding: 0.5rem;
  gap: 1rem;
  grid-column: 1 / -1;

  font-size: 0.8rem;
  align-items: start;
  justify-items: start;
`

export const CalendarRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1.5rem);
  justify-items: center;
  border: 1px solid var(--gray-5);

`;  

export const HeaderRow = styled.div`
  height: 1.5rem;
  background: var(--calender-header-color);
  grid-column: 1 / -1;
  width: 100%;
  color: var(--calender-header-text-color);
  display: flex;
  font-weight: 650;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  border-bottom: 1px solid var(--gray-5);
`

export const Legend = styled.div`
  --multiplier: 0.4;
  display: grid;
  grid-template-columns: calc(var(--size) * 0.6) 1fr 5rem;
  justify-items: start;
  align-items: center;
  font-size: 0.8rem;
  gap: 0.2rem;
  padding: 0.2rem 0;
  border-bottom: 1px solid var(--calender-header-subtext-color);
`

export const LegendCircle = styled.div`
  height: calc(var(--size) * var(--multiplier));
  width: calc(var(--size) * var(--multiplier));
  background: var(--bg-color);
  border-radius: 100%;
  justify-self: center;
`;

export default function Calendar(props) {
  let [project, setProject] = useState(props.project);
  let [startDate, setStartDate] = useState(!!project.feedbackParticipantInitialDate ? new Date(project.feedbackParticipantInitialDate) : new Date());
  let [daysBetween, setDaysBetween] = useState('');

  useEffect(() => {
    setProject(props.project);
    const _startDate = getStartDate(project);
    setStartDate(_startDate);

    const _lastDate = getLastDate(project);
    
    setDaysBetween(differenceInDays(_lastDate, _startDate));
  }, [props, project]);

  return (
    <CalendarContainer>
      <div>
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-start-color)'}}/>
          <div> Start Date</div>
          <div> {formatDate(project.feedbackParticipantInitialDate)}</div>
        </Legend>
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-participant-reminder-color)'}}/>
          <div> Participant Reminder</div>
          <div> {formatDate(project.feedbackParticipantReminderDate)}</div>
        </Legend>
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-participant-deadline-color)'}}/>
          <div> Participant Deadline</div>
          <div> {formatDate(project.feedbackParticipantDeadlineDate)}</div>
        </Legend>
        {!project.sendObserverEmailImmediately && (
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-observer-start-color)'}}/>
          <div> Observer Initial</div>
          <div> {formatDate(project.feedbackObserverInitialDate)}</div>
        </Legend>)}
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-observer-reminder-color)'}}/>
          <div> Observer Reminder</div>
          <div> {formatDate(project.feedbackObserverReminderDate)}</div>
        </Legend>
        <Legend> 
          <LegendCircle style={{'--bg-color': 'var(--calendar-observer-deadline-color)'}}/>
          <div> Observer Deadline</div>
          <div> {formatDate(project.feedbackObserverDeadlineDate)}</div>
        </Legend>
      </div>
      <CalendarRow>
        <HeaderRow>{!!startDate && startDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</HeaderRow>
        <HeaderDay title={"S"}/>
        <HeaderDay title={"M"}/>
        <HeaderDay title={"T"}/>
        <HeaderDay title={"W"}/>
        <HeaderDay title={"T"}/>
        <HeaderDay title={"F"}/>
        <HeaderDay title={"S"}/>
        {createDays(project, startDate, daysBetween)}
      </CalendarRow>
    </CalendarContainer>
  );
}

function createDays(project, startDate, daysBetween) {
  let days = [];
  for (let i = 0; i < daysBetween + 1; i++) {
    const date = addDays(startDate, i);
    let day = date.getDate();
    if (day === 1) {
      day = `${date.toLocaleString('default', { month: 'short' })}`
    }
    let type;

    if (compareDateAndField(date, project.feedbackParticipantInitialDate)) {
      type = 'PARTICIPANT_START';
    }

    if (compareDateAndField(date, project.feedbackParticipantReminderDate)) {
      type = 'PARTICIPANT_REMINDER';
    }

    if (compareDateAndField(date, project.feedbackParticipantDeadlineDate)) {
      type = 'PARTICIPANT_DEADLINE';
    }

    if (compareDateAndField(date, project.feedbackObserverInitialDate) && !project.sendObserverEmailImmediately) {
      type = 'OBSERVER_START';
    }

    if (compareDateAndField(date, project.feedbackObserverReminderDate)) {
      type = 'OBSERVER_REMINDER';
    }

    if (compareDateAndField(date, project.feedbackObserverDeadlineDate)) {
      type = 'OBSERVER_DEADLINE';
    }

    const _project = {
      day: day,
      type: type,
      nextMonth: Math.abs((startDate.getMonth() - date.getMonth()) % 2) === 1,
      index: i
    }

    days.push(<Day key={i} project={_project}/>);
  }
  return days;
}

function getStartDate(project) {
  let date = dateIsValidForCalendar(project.feedbackParticipantInitialDate) ? dateIsValidForCalendar(project.feedbackParticipantInitialDate) : new Date();
  date = getEarliestDate(project.feedbackParticipantReminderDate, date);
  date = getEarliestDate(project.feedbackParticipantDeadlineDate, date);
  date = getEarliestDate(project.feedbackObserverInitialDate, date);
  date = getEarliestDate(project.feedbackObserverReminderDate, date);
  date = getEarliestDate(project.feedbackObserverDeadlineDate, date);

  if (isSunday(date)) {
    return date;
  }  
  return previousSunday(date)
}

function getLastDate(project) {
  let date = dateIsValidForCalendar(project.feedbackParticipantInitialDate) ? dateIsValidForCalendar(project.feedbackParticipantInitialDate) : new Date();  
  date = getLatestDate(project.feedbackParticipantReminderDate, date);
  date = getLatestDate(project.feedbackParticipantDeadlineDate, date);
  date = getLatestDate(project.feedbackObserverInitialDate, date);
  date = getLatestDate(project.feedbackObserverReminderDate, date);
  date = getLatestDate(project.feedbackObserverDeadlineDate, date);
  
  if (isSaturday(date)) {
    return nextSaturday(date);
  }
  return nextSaturday(nextSaturday(date));
}

function compareDateAndField(date, field) {
  if (!isValid(field)) {
    field = parseISO(field);
  }
  return isSameDay(date, field);
}

// TODO fix this
function formatDate(date) {
  if (isValid(date)) return format(date, 'd MMM yyyy');

  date = parseISO(date);
  if (!isValid(date)) {
    return '';
  }
  return format(date, 'd MMM yyyy');
}

function getEarliestDate(before, after) {
  if (isValid(before)) return isBefore(before, after) ? before : after;
  if (!before) return after;
  if (!before.match(/^\d{4}-\d{2}-\d{2}$/)) return after;
  const beforeParsed = parseISO(before);
  if (!isValid(beforeParsed)) return after;
  return isBefore(beforeParsed, after) ? beforeParsed : after;
}

function getLatestDate(before, after) {
  if (isValid(before)) return isAfter(before, after) ? before : after;
  if (!before) return after;
  if (!before.match(/^\d{4}-\d{2}-\d{2}$/)) return after;
  const beforeParsed = parseISO(before);
  if (!isValid(beforeParsed)) return after;
  return isAfter(beforeParsed, after) ? beforeParsed : after;
}

function dateIsValidForCalendar(date) {
  if (isValid(date)) return date;
  if (!date) return false;
  if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) return false;
  if (!isValid(parseISO(date))) return false;
  return parseISO(date);
}