import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Formik, Form} from 'formik';
import {
    PageContainer,
    JustSubmittedComponent,
    TooLateComponent,
    ErrorComponent,
    ErrorMessageDiv
} from "../component/UI";
import NotificationService from '../api/NotificationService';
import ParticipantApi from "../api/ParticipantApi";
import ObserverApi from "../api/ObserverApi";
import {createEmptyContact} from '../component/projects/ContactEntryForm.js';
import {ObserverSchema} from '../util/SchemaUtils';
import {Title, PreTitle, SubTitle, SubmitButton} from "../component/UI.js";
import ContactEntryForm from '../component/projects/ContactEntryForm';
import {isAfter, startOfToday} from 'date-fns';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

export default function ParticipantResponsePage(props) {
    let {setShowHeader} = props;
    let [participant, setParticipant] = useState({});
    let [state, setState] = useState({
        loaded: false,
        tooLate: false,
        justSubmitted: false,
        submitted: false,
        error: false,
        supportName: '',
        supportEmail: ''
    });
    const {id} = useParams();
    let [initialValues, setInitialValues] = useState({
        observers: [createEmptyContact(), createEmptyContact(), createEmptyContact()]
    })

    useEffect(() => {
        setShowHeader(false);
    }, [setShowHeader]);

    useEffect(() => {
        ParticipantApi.findById(id, true).then(_response => {
            const _participant = _response.participant;
            _participant.loaded = true;
            const observers = _participant.observers || [];
            setParticipant(_participant);

            const min = _response.configuration.minimumNumberOfObservers;
            setState(s => {
                return {
                    ...s,
                    loaded: true,
                    submitted: observers.length >= min,
                    tooLate: !_response.configuration.allowParticipantLateEntry && isAfter(startOfToday(), new Date(_response.configuration.feedbackParticipantDeadlineDate.slice(0, -1))),
                    minimumNumberOfObservers: min,
                    maximumNumberOfObservers: _response.configuration.maximumNumberOfObservers,
                    supportName: _response.configuration.supportName,
                    supportEmail: _response.configuration.supportEmail,
                }
            });
            if (observers.length >= min) {
                setInitialValues({
                    observers: [...observers, createEmptyContact()]
                });
            } else {
                setInitialValues({
                    observers: [...Array(min)].map(() => createEmptyContact())
                })
            }
        }).catch(e => {
            setState(s => {
                return {...s, error: true}
            });
            console.log(e);
        });
    }, [id, setState]);

    async function submitForm(values) {
        console.log('saveAndSubmit', values);
        try {
            await ObserverApi.saveObserversForParticipants(participant.id, values);
            NotificationService.success("Successfully saved feedback observers.");
            setParticipant({...participant});
            setState({
                ...state,
                justSubmitted: true
            })

        } catch (e) {
            NotificationService.error(`Failed to save feedback observers. Please try again later, if issues persists please reach out to ${state.supportName}.`)
            console.error('Failed to save participant observers', e);
        }
    }

    return (
        <div className="center">
            <PageContainer>
                <LoadComponent participant={participant} state={state} initialValues={initialValues}
                               submitForm={submitForm}/>
            </PageContainer>
        </div>
    );
}

function LoadComponent(props) {
    const {participant, state, initialValues, submitForm} = props;

    if (state.error) {
        return <ErrorComponent title={'Oops. Something went wrong'}
                               subtitle={'Sorry, the page you are looking for is temporarily unavailable.'}
                               supportName={state.supportName} supportEmail={state.supportEmail}></ErrorComponent>;
    }
    if (state.justSubmitted) {
        return <JustSubmittedComponent firstName={participant.firstName} lastName={participant.lastName}
                                       type='participant' supportName={state.supportName}
                                       supportEmail={state.supportEmail}/>
    }
    if (state.tooLate) {
        return <TooLateComponent firstName={participant.firstName} lastName={participant.lastName} type='participant'
                                 supportName={state.supportName} supportEmail={state.supportEmail}/>
    }
    if (state.loaded) {
        return <ObserverEntryContent participant={participant} initialValues={initialValues} submitForm={submitForm}
                                     minimumNumberOfObservers={state.minimumNumberOfObservers}
                                     maximumNumberOfObservers={state.maximumNumberOfObservers}
                                     supportEmail={state.supportEmail}/>
    }
    return <></>
}

function ObserverEntryContent(props) {
    const {
        supportEmail,
        participant,
        initialValues,
        submitForm,
        minimumNumberOfObservers,
        maximumNumberOfObservers
    } = props;
    return (<>
        <PreTitle> {participant.firstName} {participant.lastName} </PreTitle>
        <Title> Nominate Observers</Title>
        <SubTitle> Please select {minimumNumberOfObservers}-{maximumNumberOfObservers} people to give you feedback and
            press submit. Enter each of their names and emails below, then press submit.
            If you have any questions, please contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.</SubTitle>
        <Formik key="observers" initialValues={initialValues} validationSchema={ObserverSchema}
                onSubmit={async _values => {
                    submitForm(_values)
                }}>
            {(props) =>
                <Form>
                    <ContactEntryForm values={props.values} errors={props.errors} fieldName="observers"
                                      fieldLabel="observer" min={minimumNumberOfObservers}
                                      max={maximumNumberOfObservers} setFieldValue={props.setFieldValue}/>
                    <div className="center">
                        {Object.keys(props.errors).length > 0 &&
                            <ErrorMessageDiv> <FontAwesomeIcon icon={faTriangleExclamation}/>There is at least one field
                                missing or invalid <FontAwesomeIcon icon={faTriangleExclamation}/></ErrorMessageDiv>}
                        <SubmitButton type="submit"
                                      style={{marginTop: "10px", justifySelf: "center"}}>Submit</SubmitButton>
                    </div>
                </Form>}
        </Formik>
    </>)
}