import styled from "styled-components";
import './Day.css';

export const DayContainer = styled.div`
  height: var(--size);
  width: var(--size);
  background: ${props => !!props.even ? 'var(--calendar-even-row-color)' : 'var(--calendar-odd-row-color)'};
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div`
  color: var(--calendar-row-text-color);
  font-size: calc(var(--size) * 0.5);
  border-radius: 100%;
  background: var(--bg-color);
  width: calc(var(--size) * 0.8);
  height: calc(var(--size) * 0.8);
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export default function Day(props) {
  const project = props.project;
  return (
    <DayContainer even={Math.floor(project.index/7) % 2 === 0}>
      <Label className={!!project.nextMonth ? "nextMonth" : ""} 
        style={{"--bg-color": getBackgroundColor(project.type), 
        color: !!project.type ? 'var(--calendar-row-with-bg-text-color)' : ''}}
          >{project.day}</Label>
    </DayContainer>
  );
}

function getBackgroundColor(type) {
  if (type === 'PARTICIPANT_START') {
    return 'var(--calendar-start-color)';
  }
  if (type === 'PARTICIPANT_REMINDER') {
    return 'var(--calendar-participant-reminder-color)';
  }
  if (type === 'OBSERVER_START') {
    return 'var(--calendar-observer-start-color)';
  }
  if (type === 'OBSERVER_REMINDER') {
    return 'var(--calendar-observer-reminder-color)';
  }
  if (type === 'PARTICIPANT_DEADLINE') {
    return 'var(--calendar-participant-deadline-color)';
  }
  if (type === 'OBSERVER_DEADLINE') {
    return 'var(--calendar-observer-deadline-color)';
  }
}
