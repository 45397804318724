import styled from "styled-components";
import {useState} from 'react';
import {dateIsOnOrAfterToday} from '../../util/DateUtils';
import {PageContainer, CardContent, Title, PreTitle, OutlineButton} from "../UI.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass2, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NotificationService from '../../api/NotificationService';
import ProjectApi from '../../api/ProjectApi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {format} from 'date-fns'

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const ProjectTableEl = styled.table`
  background: var(--secondary-color);
  text-align: left;
  border-collapse: collapse;
  border-top: 1px solid var(--dark-color);
  border-bottom: 1px solid var(--dark-color);
  thead, tfoot {
    background: var(--blue-color);
  }
  th {
    font-weight: 600;
    color: white;
    text-align: center;
  }
  tr {
    font-size: 0.8rem;
  }
  th,td {
    padding: .2rem;
    border-left: 1px solid var(--dark-color);
    border-right: 1px solid var(--dark-color);
  }
  td {
    padding-left: 0.5rem;
  }
`

const TableIcon = styled(FontAwesomeIcon)`
  color: var(--secondary-color);
`;

export default function ProjectSummaryTable(props) {
  let [selectedRows, setSelectedRows] = useState([]);
  const projects = props.projects;
  let [selectedProjects, setSelectedProjects] = useState([]);

  return (
    <PageContainer style={{padding: "1rem"}}>
      <CardContent>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: "10px"}}>
          <div>
            <PreTitle>View</PreTitle>
            <Title> Summary Table</Title>
          </div>
        </div>
        <ProjectTableEl>
          <thead>
            <tr>
              <th colSpan={2} rowSpan={2}>Project Name</th>
              <th rowSpan={2}>Start Date</th>
              <th colSpan={2}>Participants</th>
              <th colSpan={2}>Observers</th>
              <th colSpan={2}>Reports</th>
              <th rowSpan={2}>Status</th>
            </tr>
  {/* TODO does mum want end date? */}
            <tr style={{borderBottom: "1px solid var(--dark-color)"}}>
              <th style={{maxWidth: "30px", borderRight: "0"}}><Tippy content="Complete"><span><TableIcon icon={faClipboardCheck}/></span></Tippy></th>
              <th style={{maxWidth: "30px", borderLeft: "0"}}><Tippy content="Pending"><span><TableIcon icon={faHourglass2}/></span></Tippy></th>
              <th style={{maxWidth: "30px", borderRight: "0"}}><Tippy content="Complete"><span><TableIcon icon={faClipboardCheck}/> </span></Tippy></th> 
              <th style={{maxWidth: "30px", borderLeft: "0"}}><Tippy content="Pending"><span><TableIcon icon={faHourglass2}/></span></Tippy></th> 
              <th style={{maxWidth: "30px", borderRight: "0"}}><Tippy content="Complete"><span><TableIcon icon={faClipboardCheck}/> </span></Tippy></th> 
              <th style={{maxWidth: "30px", borderLeft: "0"}}><Tippy content="Pending"><span><TableIcon icon={faHourglass2}/></span></Tippy></th> 
            </tr>
            </thead>
          <tbody>
            <CreateProjectRows projects={projects} selectedRows={selectedRows} setSelectedRows={setSelectedRows} selectedProjects={selectedProjects} setSelectedProjects={setSelectedProjects}/>
          </tbody>
        </ProjectTableEl>
        <ButtonRow selectedRows={selectedRows} selectedProjects={selectedProjects}/>      
      </CardContent>
    </PageContainer>
  );
}

function ButtonRow(props) {
  let navigate = useNavigate();

  const viewProjectDetails = function() {
    if (props.selectedProjects.length === 0) {
     return NotificationService.warning("You must select a project to view details.");
    } 
    navigate('/details', { state : {projects: props.selectedProjects}})
  }

  const archiveProjects = function() {
    if (props.selectedProjects.length === 0) {
      return NotificationService.warning("You must select a project to archive.");
    } 
    let promises = [];
    for (let i = 0; i < props.selectedRows.length; i++) {
      promises.push(ProjectApi.archiveProject(props.selectedProjects[i].id))
    }
    Promise.all(promises).then(() => {
      NotificationService.success("Successfully archived projects");
    }).catch(error => {
      console.error('Failed to archive projects', error);
      NotificationService.error(`Failed to archive project. Try again later, if issues persists please reach out to ${SUPPORT_EMAIL}.`)
    });

  }
  
  return (
    <div style={{display: "flex", gap: "1rem", marginTop: "10px"}}>
      <OutlineButton onClick={() => viewProjectDetails()}>View Details</OutlineButton>
      <OutlineButton onClick={() => archiveProjects()}>Archive Project</OutlineButton>
      <OutlineButton onClick={() => navigate(`/create`)}> New Project </OutlineButton>
    </div>)  
}

function CreateProjectRows(props) {
  let {projects, selectedRows, setSelectedRows, selectedProjects, setSelectedProjects} = props;

  const selectRow = function(index) {
    const indexOf = selectedRows.indexOf(index);
    if (indexOf >= 0) {
      selectedRows.splice(indexOf, 1);
      selectedProjects.splice(indexOf, 1);
      setSelectedRows([...selectedRows]);
      setSelectedProjects([...selectedProjects])
    } else {
      selectedRows.push(index);
      setSelectedRows([...selectedRows]);
      selectedProjects.push(projects[index]);
      setSelectedProjects([...selectedProjects]);
    }
  }

  let projectRows = [];
  projects.forEach((project, i) => {
    const selected = selectedRows.includes(i);
    projectRows.push(
    <tr key={`projectRow${project.id}`} onClick={() => selectRow(i)} className={selected ? 'selected' : ''}>
      <td style={{width: "35px"}}><input type="checkbox" checked={selected} onChange={() => {}}></input></td>
      <td>{project.projectName}</td>
      <td>{formatDate(project.feedbackParticipantInitialDate)}</td>
      <td style={{textAlign: "center"}}>{project.participantsComplete}</td>
      <td style={{textAlign: "center"}}>{project.participantsPending} </td>
      <td style={{textAlign: "center"}}>{project.observersComplete}</td>
      <td style={{textAlign: "center"}}>{project.observersPending}</td>
      <td style={{textAlign: "center"}}>{project.reportsComplete}</td>
      <td style={{textAlign: "center"}}>{project.reportsPending}</td>
      <td style={{maxWidth: "60px"}}>{getStatusForNextDate(project)}</td>
    </tr>);
  });
  return projectRows;
}

function getStatusForNextDate(project) {
  if (dateIsOnOrAfterToday(project.feedbackParticipantInitialDate)) {
    return "Project Not Yet Started";
  }
  if (dateIsOnOrAfterToday(project.feedbackParticipantReminderDate)) {
    return "Participants Contacted";
  }
  if (dateIsOnOrAfterToday(project.feedbackParticipantDeadlineDate)) {
    return "Participants Reminded";
  }
  if (dateIsOnOrAfterToday(project.feedbackObserverInitialDate)) {
    return "Observers Not Yet Contacted";
  }
  if (dateIsOnOrAfterToday(project.feedbackObserverReminderDate)) {
    return "Observers Contacted";
  }
  if (dateIsOnOrAfterToday(project.feedbackObserverDeadlineDate)) {
    return "Observers Reminded";
  }
  return "Project Complete"
}

function formatDate(date) {
  return format(new Date(date), 'MMMM do yyyy');
}