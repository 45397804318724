import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import HomePage from "./page/HomePage";
import ProjectSummaryPage from "./page/ProjectSummaryPage";
import ProjectCreatePage from "./page/ProjectCreatePage";
import ParticipantResponsePage from "./page/ParticipantResponsePage";
import ObserverResponsePage from "./page/ObserverResponsePage";
import ProjectDetailsPage from "./page/ProjectDetailsPage";
import PrivacyPage from "./page/PrivacyPage";
import DeleteMyData from "./page/DeleteMyData";

export default function PageRouter(props) {
    let navigate = useNavigate();

    function navigateToHome() {
        navigate('/admin');
    }

    return (
        <Routes>
            <Route path="*" element={<HomePage/>}/>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/privacy" element={<PrivacyPage/>}/>
            <Route path="/deletemydata" element={<DeleteMyData/>}/>
            <Route path="/admin" element={<ProjectSummaryPage/>}/>
            <Route path="/details" element={<ProjectDetailsPage navigateToHome={navigateToHome}/>}/>
            <Route path="/details/:id" element={<ProjectDetailsPage navigateToHome={navigateToHome}/>}/>
            <Route path="/create" element={<ProjectCreatePage navigateToHome={navigateToHome}/>}/>
            <Route path="/participants/:id" element={<ParticipantResponsePage setShowHeader={props.setShowHeader}/>}/>
            <Route path="/observers/:id" element={<ObserverResponsePage setShowHeader={props.setShowHeader}/>}/>
        </Routes>
    );
}
