import '@fortawesome/fontawesome-svg-core/styles.css';
import {SplashContainer, LeftContent, RightContent, SplashSubmitButton, ErrorMessageDiv, FormGroup} from "../component/UI.js";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {DeleteMyDataSchema} from '../util/SchemaUtils';
import GDPRService from '../api/GDPRService';
import NotificationService from '../api/NotificationService';

export default function DeleteMyData() {
    const initialValues = {name: '', email: '', message: ''};

    return (
        <SplashContainer>
            <LeftContent style={{alignSelf: "start"}}>
                <h2>Delete My Data</h2>
                <p>
                    If you would like your data deleted from the Mini 360 platform, you can fill this form out. We will
                    match your name and email with our records and verify your identify by reaching out to the email we
                    have on our file. If you submit this form with the same email used for survey and account creation
                    our team will be able to process your request more efficiently.
                </p>
            </LeftContent>
            <Formik validationSchema={DeleteMyDataSchema} initialValues={initialValues}
                    onSubmit={async (values, actions) => {
                        try {
                            await GDPRService.deleteMyData(values);
                            NotificationService.success('Thank you for you submitting a request to delete your data, a member of our team will be in touch shortly.')
                            actions.resetForm(initialValues);
                        } catch (e) {
                            console.log(e);
                            NotificationService.error('Something went wrong, please try again later.')
                        }
                    }}>
                <Form>
                    <RightContent>
                        <FormGroup>
                            <label>Full Name</label>
                            <Field id='name' name='name' placeholder='Andrew Weeks'/>
                            <ErrorMessageDiv><ErrorMessage name='name'/></ErrorMessageDiv>
                        </FormGroup>
                        <FormGroup>
                            <label>Email</label>
                            <Field id='email' name='email' placeholder='andrew@yourwebsite.com'/>
                            <ErrorMessageDiv><ErrorMessage name='email'/></ErrorMessageDiv>
                        </FormGroup>
                        <FormGroup>
                            <label>Message</label>
                            <Field id='message' name='message' as="textarea"/>
                            <ErrorMessageDiv><ErrorMessage name='message'/></ErrorMessageDiv>
                        </FormGroup>
                        <div style={{justifySelf: "center"}}>
                            <SplashSubmitButton type="submit">Send!</SplashSubmitButton>
                        </div>
                    </RightContent>
                </Form>
            </Formik>
        </SplashContainer>);
}