import Api from "./Api";

const UserApi = {
  signIn: async function (email, password) {
    const response = await Api.post(`/users/signIn`, {
      email: email,
      password: password
    });
    return response.data;
  },

  updateSignIn: async function (id) {
    const response = await Api.get(`/users/signIn/${id}`);
    return response.data;
  }
};

export default UserApi;
