import Api from "./Api";

let logos = [];

const LogoApi = {
  getAll: async function () {
    const response = await Api.get(`/logos`);
    logos = response.data;
    return response.data;
  },

  getIndex: function(id) {
    for (let i = 0; i < logos.length; i++) {
      if (id === logos[i].id) {
        return logos[i];
      }
    }
    return null;
  }
};

export default LogoApi;
