import '@fortawesome/fontawesome-svg-core/styles.css';
import {SplashContainer, LeftContent, RightContent, SplashSubmitButton, ErrorMessageDiv, FormGroup, SubTitle} from "./UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {SplashSchema} from '../util/SchemaUtils';
import LeadService from '../api/LeadService';
import NotificationService from '../api/NotificationService';

export default function SplashComponent() {
  const initialValues = { name: '', email: '', message: '' };
  
  return (
  <SplashContainer>
    <LeftContent>
      <h2>"Mini 360"</h2>
      <SubTitle noLeft>Getting punctual, brief and relevant feedback for individuals and team -
       aimed at developing individuals and teams.</SubTitle>
      <p>
      Mini360 is an easily customisable feedback instrument that allows individuals or/and teams
      to get punctual feedback on specific issues of relevance to them.  There are up to 10 possible 
      questions, which can be adapted or updated to your specific situation.  We work together to agree 
      the questions and then each participant chooses their own observers to give the feedback.  
      The whole process can be completed within a couple of weeks - to ensure it is punctual, 
      relevant, and easy to repeat to measure progress.
      </p>
      <p>
        Where relevant or helpful, we have a team of executive coaches available to support 
        individuals and / or teams as part of a development process.
      </p>
      <p>
        <b>Register to learn more about the “Mini 360” or the indiviudall / team coaching possibilities.</b>
      </p>
      <ul className="fa-ul">
        <li><span className="fa-li"><FontAwesomeIcon icon={faCheck}/></span>Relevant and punctual feedback</li>
        <li><span className="fa-li"><FontAwesomeIcon icon={faCheck}/></span>Personalised to your current development needs or goal</li>
        <li><span className="fa-li"><FontAwesomeIcon icon={faCheck}/></span>Fully automated system to solicit feedback, send reminders if needed, and produce fully confidential reports to the participant (only)</li>
        <li><span className="fa-li"><FontAwesomeIcon icon={faCheck}/></span>GPDR Compliant <i>see our privacy policy <a href="/privacy" target="_blank">here</a></i></li>
      </ul>
    </LeftContent>
    <Formik validationSchema={SplashSchema} initialValues={initialValues}
      onSubmit={async (values, actions) => { 
        try {
          await LeadService.register(values);
          NotificationService.success('Thank you for your interest, a member of our team will be in touch shortly.')
          actions.resetForm(initialValues);
        } catch (e) {
          console.log(e);
          NotificationService.error('Something went wrong, please try again later.')
        }
      }}>
      <Form>
        <RightContent>      
          <FormGroup>
            <label>Full Name</label>
            <Field id='name' name='name' placeholder='Andrew Weeks'/>
            <ErrorMessageDiv><ErrorMessage name='name'/></ErrorMessageDiv>
          </FormGroup>
          <FormGroup>
            <label>Email</label>
            <Field id='email' name='email' placeholder='andrew@yourwebsite.com'/>
            <ErrorMessageDiv><ErrorMessage name='email'/></ErrorMessageDiv>
          </FormGroup>
          <FormGroup>
            <label>Message</label>
            <Field id='message' name='message' as="textarea"/>
            <ErrorMessageDiv><ErrorMessage name='message'/></ErrorMessageDiv>
          </FormGroup>
          <div style={{justifySelf: "center"}}>
            <SplashSubmitButton type="submit">Send!</SplashSubmitButton>
          </div>
        </RightContent>
      </Form>
    </Formik>
  </SplashContainer>);
}