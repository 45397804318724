import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {lazy} from '../util/Utils';
import ProjectApi from '../api/ProjectApi';
import SessionService from '../api/SessionService';
import ProjectSummaryTable from '../component/projects/ProjectSummaryTable';
import ProjectSummaryChart from '../component/projects/ProjectSummaryChart';
import {isAfter, parseISO} from 'date-fns';

export default function ProjectSummaryPage() {
    const [projects, setProjects] = useState([]);
    const [dashboardData, setDashboardData] = useState({
        activeProjects: 'X',
        participantsComplete: 'X',
        participantsPending: 'X',
        observersComplete: 'X',
        observersPending: 'X',
        reportsComplete: 'X',
        reportsPending: 'X',
    });

    let navigate = useNavigate();

    useEffect(lazy(async () => {
        if (!SessionService.isSessionValid()) {
            navigate('/', {state: {loggedOut: true}});
        }
        const _projects = await ProjectApi.getAllProjects(SessionService.getSession().user.id);
        let activeProjects = 0;
        let participantsComplete = 0;
        let participantsPending = 0;
        let observersComplete = 0;
        let observersPending = 0;
        let reportsComplete = 0;
        let reportsPending = 0;

        for (let i = 0; i < _projects.length; i++) {

            let project = _projects[i];
            project.feedbackParticipantInitialDate = new Date(project.feedbackParticipantInitialDate.slice(0, -1));
            project.feedbackParticipantReminderDate = new Date(project.feedbackParticipantReminderDate.slice(0, -1));
            project.feedbackParticipantDeadlineDate = new Date(project.feedbackParticipantDeadlineDate.slice(0, -1));
            project.feedbackObserverInitialDate = new Date(project.feedbackObserverInitialDate.slice(0, -1));
            project.feedbackObserverReminderDate = new Date(project.feedbackObserverReminderDate.slice(0, -1));
            project.feedbackObserverDeadlineDate = new Date(project.feedbackObserverDeadlineDate.slice(0, -1));

            project.participantsComplete = 0;
            project.participantsPending = 0;
            project.observersComplete = 0;
            project.observersPending = 0;
            project.reportsComplete = 0;
            project.reportsPending = 0;
            project.pastDue = isAfter(new Date(), parseISO(project.feedbackParticipantDeadlineDate));

            for (let j = 0; j < project.participants.length; j++) {
                let _reportReady = project.participants.length > 0;
                let participant = project.participants[j];
                if (!!participant.observers && participant.observers.length >= project.minimumNumberOfObservers) {
                    project.participantsComplete++;
                } else {
                    project.participantsPending++;
                    _reportReady = false;
                }

                for (let k = 0; k < participant.observers.length; k++) {
                    let observer = participant.observers[k];
                    if (!!observer.answers) {
                        project.observersComplete++;
                    } else {
                        project.observersPending++;
                        _reportReady = false;
                    }
                }
                if (_reportReady) {
                    project.reportsComplete++;
                } else {
                    project.reportsPending++;
                }
            }
            if (project.feedbackObserverDeadlineDate.getTime() < new Date().getTime()) {
                continue;
            }
            activeProjects++;

            participantsComplete += project.participantsComplete;
            participantsPending += project.participantsPending;
            observersComplete += project.observersComplete;
            observersPending += project.observersPending;
            reportsComplete += project.reportsComplete;
            reportsPending += project.reportsPending;
        }
        setProjects(_projects);
        setDashboardData({
            activeProjects: activeProjects,
            participantsComplete: participantsComplete,
            participantsPending: participantsPending,
            observersComplete: observersComplete,
            observersPending: observersPending,
            reportsComplete: reportsComplete,
            reportsPending: reportsPending
        });
        console.log('projects', _projects);
    }), []);


    return (
        <div className="center">
            <ProjectSummaryChart dashboardData={dashboardData}/>
            <ProjectSummaryTable projects={projects} dashboardData={dashboardData}/>
        </div>
    );
}
