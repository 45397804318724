import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from 'formik';
import sadEmoji from '../assets/sad-emoji.png';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const CardContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  margin-top: 1rem;
`

export const PageContainer = styled(CardContainer)`
  min-width: 400px;
  max-width: 750px;
  width: clamp(80vw, 400px, 750px);
  overflow: auto;
  background: var(--primary-color);
  padding: 1rem;
  box-shadow: var(--shadow-3);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.16);

  @media (max-width: 680px) {
    width: 90vw;
    min-width: 90vw;
  }

  @media (max-width: 400px) {
    width: calc(95vw - 1rem);
    min-width: calc(95vw - 1rem);
  }
`

export const CardContent = styled.div`
  transition: all 0.3s ease 0s;
  // padding: 10px;
  display: grid;
  align-items: center;
`

export const NakedLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const SmallIcon = styled(FontAwesomeIcon)`
  font-size: 12px !important;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
`;

export const FormGroup = styled.div`
  label {
    font-size: 0.8rem;
    font-weight: 400;
    display: block;
    text-align:left;
    color: var(--gray-7)
  }

  span {
    font-size: 0.7rem;
    display: inline-block;
    font-weight: 300;
    margin-left: 0.4rem;
    transform: translateY(-3px);
    color: var(--gray-6);
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    border-radius: var(--radius-1);
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 3px;
    box-shadow: var(--shadow-1);
  }
`;

export const ErrorMessageDiv = styled.div`
  font-size: 0.75rem;
  color: var(--red-7);
  margin-top: 5px;
  margin-left: 10px;
  text-align: left;
`;

export const InputTable = styled.table`
  min-width: 400px;
  max-width: 750px;
  overflow: hidden;
  background: var(--secondary-color);
  text-align: left;
  border-collapse: collapse;
  box-shadow: var(--shadow-2);
  thead, tfoot {
    background: #1C6EA4;
  }
  th {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    padding: .5rem;
  }
  td {
    padding: .4rem;
  }
  tr {
    border-bottom: 1px solid var(--gray-4);
    min-height: 3rem;
  }
  input:not([type=checkbox]), textarea {
    width: 100%;
    padding: 10px;
    border-radius: var(--radius-1);
    border: 1px solid #ced4da;;
    margin-top: 3px;
    box-shadow: var(--shadow-1);
  }

  select {
    width: 90%;
    padding: 0.6rem 0.2rem;
    font-size: 0.7rem;
    border-radius: var(--radius-1);
    border: 1px solid #ced4da;;
  }

  @media (max-width: 680px) {
    min-width: 100%;
    max-width: 100%;
    display: block;
    table, thead, tbody, th, td, tr { 
      display: block; 
    }

    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr { border: 1px solid #ccc; }
	
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid var(--gray-4);
      position: relative;
      padding-left: 35%; 
    }

    td:nth-of-type(1) {
      background: #1C6EA4;
      color: var(--secondary-color);
    }

    td:before { 
      /* Now like a table header */
      position: absolute;
      
      /* Top/left values mimic padding */
      left: 10px;
      width: 30%; 
      padding-right: 10px; 
    }

    td:nth-of-type(n+2):before { 
      transform: translateY(50%);
    }

    td:nth-of-type(1):before { 
      content: attr(data-placeholder);
 
     }
    td:nth-of-type(2):before { content: "First Name"; }
    td:nth-of-type(3):before { content: "Last Name"; }
    td:nth-of-type(4):before { content: "Email"; }
    td:nth-of-type(5):before { content: "Delete"; }

    tr:last-child {
      td:nth-of-type(1): before { content: "";}
      td {
        background: var(--secondary-color);
        padding-left: 0;
        colspan: 0;
      }
    }
  }

`

export const TableIcon = styled.div`
  transition: all 0.3s ease 0s;
  font-size: 1.1rem;
  color: var(--gray-1);
  padding: 0.5rem 0.75rem;

  :hover {
    color: var(--gray-4);
    transform: translateY(-2px);
    box-shadow: 0 0 0 var(--size-1) hsl(210 10% 5%/25%);
  }
`;

export const AddNewRowButton = styled.div`
  text-align: center;
  background: #007bff;
  color: white;
  padding: 0.375rem 0.75rem;
  font-weight: 450;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: var(--shadow-2);

  :hover {
    background: #0069d9;
  }
`;

export const FormLabel = styled.label`
  font-size: 0.8rem;
  font-weight: 400;
  display: block;
  text-align:left;
  color: var(--gray-7)
`

export const FormSubTitle = styled.span`
  font-size: 0.7rem;
  display: inline-block;
  font-weight: 300;
  margin-left: 0.4rem;
  padding-top: 0.15rem;
  transform: translateY(-3px);
  color: var(--gray-6);
`

export const InputField = styled(Field)`
  width: 100%;
  padding: 10px;
  border-radius: var(--radius-1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 3px;
  box-shadow: var(--shadow-1);
`

export function FormInput(props) {
  const inputName = props.input.name;
  return (
  <FormGroup className={props.className}>
    <FormLabel> {props.label} </FormLabel>
    {!!props.subtitle && <FormSubTitle>{props.subtitle}</FormSubTitle>}
    {!!props.input.onChange && <Field id={inputName} name={inputName} placeholder={props.input.placeholder} onChange={props.input.onChange}/>}
    {!props.input.onChange &&  <Field id={inputName} name={inputName} placeholder={props.input.placeholder}/>}
    
    {props.input.errors[inputName] && props.input.touched[inputName] && (
             <ErrorMessageDiv>{props.input.errors[inputName]}</ErrorMessageDiv>
           )}
  </FormGroup>)
}

export function CustomizationInput(props) {
  const inputName = props.input.name;
  return (<>
    <div>
      <FormLabel> {props.label} </FormLabel>
      {!!props.subtitle && <FormSubTitle>{props.subtitle}</FormSubTitle>}
    </div>
    <div>
      {!!props.input.onChange && <InputField id={inputName} name={inputName} placeholder={props.input.placeholder} onChange={props.input.onChange}/>}
      {!props.input.onChange &&  <InputField id={inputName} name={inputName} placeholder={props.input.placeholder}/>}
      
      {props.input.errors[inputName] && props.input.touched[inputName] && (
                <ErrorMessageDiv>{props.input.errors[inputName]}</ErrorMessageDiv>
              )}
    </div>
    </>)
}

export const NumberField = styled(Field)`
  width: 100%;
  max-width: 4rem;
  padding: 5px 2px;
  text-align: center;
  border-radius: var(--radius-1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: var(--shadow-1);
`
export function NumberInput(props) {
  const inputName = props.input.name;
  return (<>
    <FormLabel> {props.label} </FormLabel>
    {!!props.subtitle && <FormSubTitle>{props.subtitle}</FormSubTitle>}
    <NumberField id={inputName} name={inputName} type="number"/>
    {props.input.errors[inputName] && props.input.touched[inputName] && (
             <ErrorMessageDiv>{props.input.errors[inputName]}</ErrorMessageDiv>
           )}
  </>)
}

export function CheckboxField(props) {
  const inputName = props.name;
  return (<>
    <div>
      <FormLabel> {props.label} </FormLabel>
      {!!props.subtitle && <FormSubTitle>{props.subtitle}</FormSubTitle>}
    </div>
    <div>
      <Field type="checkbox" name={inputName} />
    </div>
  </>)
}

export function SelectInput(props) {
  const inputName = props.name;
  return (<>
    <div>
      <FormLabel> {props.label} </FormLabel>
      {!!props.subtitle && <FormSubTitle>{props.subtitle}</FormSubTitle>}
    </div>
    <div>
      <Field as="select" name={inputName}>
        {props.options.map(option => <option key={`${inputName}-${option.value}`} value={option.value}>{option.displayValue}</option>)}
      </Field>
    </div>
  </>)
}

export const FormGroupAlt = styled.div`
  label {
    font-size: 0.8rem;
    font-weight: 200;
    display: block;
    text-align:left;
    color: var(--gray-7)
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--dark-color);
`

export const InputSelectGroup = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  border: 1px solid #d9dbde;
  border-radius: 4px;
  height: 2rem;
  max-width: 8rem;
  margin-top: 0.2rem;

  span {
    display: flex;
    align-items: center;
    padding: 0.4rem;
    background-color: var(--secondary-color);
    border-right: 1px solid #d9dbde;
  }

  input[type=number] {
    width: 100%;
    height: 100%;
    font-weight: 400;
    text-align: center;
    background-color: var(--primary-color);
    margin: 0;
    border: 0;
    padding: 0 0 0 0.25rem;
  }
`

const Placeholder = styled.div`
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0;
  

  ::after {
    position: absolute;
    right: 3px;
    top: 12.5px;
    content: attr(data-placeholder);
    pointer-events: none;
    opacity: 0.6;
    font-size: 0.5rem;
  }

`

export function OptionalNumberInput(props) {
  const inputName = props.input.name;
  return (
  <FormGroupAlt className={props.className} grid2>
    <label> {props.label} </label>
    <InputSelectGroup> 
      <span>
        <input type="checkbox" checked={props.input.values[inputName] >= 0} onChange={() => props.setFieldValue(inputName, props.input.values[inputName] >= 0 ? -1 : 7)}></input>
      </span>
      
      {props.input.values[inputName] >= 0 ? <Placeholder data-placeholder="days after"><Field id={inputName} type="number" name={inputName} placeholder={props.input.placeholder} disabled={props.input.values[inputName] === -1}/></Placeholder> : <input type="number" style={{background: 'var(--secondary-color)'}}/>}
      
    </InputSelectGroup>
      {props.input.errors[inputName] && props.input.touched[inputName] && (
        <ErrorMessageDiv>{props.input.errors[inputName]}</ErrorMessageDiv>
      )}
  </FormGroupAlt>)
}

export const CTAButton = styled.button`
  transition: all 0.3s ease 0s;
  --_highlight-size: 0;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  width: 7rem;
  color: var(--secondary-color);
  background-color: var(--dark-color);
  border-radius: var(--radius-2);
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 0 0 0 var(--_highlight-size) hsl(210 10% 5%/25%);

  :hover {
      --_highlight-size: var(--size-1);
      background-color: var(--gray-7);
      transform: translateY(-2px);
  }
`;

export const OutlineButton = styled(CTAButton)`
  border: 1px solid var(--blue-color);
  cursor: pointer;
  width: auto;
  color: var(--blue-color);
  background-color: transparent;
  padding: 4px 8px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 0 0 0 var(--_highlight-size) hsl(210 10% 5%/25%);

  :hover {
      --_highlight-size: var(--size-1);
      background-color: var(--blue-highlight-color);
      transform: translateY(-2px);
  }
`

export const SubmitButton = styled(CTAButton)`
  width: 7rem;
  color: var(--gray-2);
  background-color: var(--gray-7);
  border-radius: var(--radius-2);

  :hover {
      --_highlight-size: var(--size-1);
      background-color: var(--gray-8);
      transform: translateY(-2px);
  }
`;

export const Title = styled.div`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 600;
  color: var(--gray-7);
`

export const Title2 = styled.div`
  margin: 0;
  font-size: 1.15rem;
  line-height: 1.4;
  font-weight: 500;
  color: var(--gray-7);
  display: flex;
  align-items: center;
`

export const SubTitle2 = styled.div`
  color: var(--faded-text-color);
  font-size: 1rem;
  font-weight: 500;
  text-align: start;
`

export const SubTitle = styled.div`
  color: var(--faded-text-color);
  font-size: 0.75rem;
  text-align: start;
  margin-bottom: 10px;
  padding-left: ${props => !!props.noLeft ? '' : '1rem'};
  padding-right: 1rem;

  a {
    color: var(--dark-color);
  }
`

export const PreTitle = styled.div`
  margin: 0;
  font-size: .625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .04em;
  line-height: 1.6;
  color: #626976;
  display: flex;
  align-items: center;
`

export function ErrorComponent(props) {
  return (
    <>
      <img src={sadEmoji} width={100} height={100} style={{borderRadius: '100%', margin: '20px', boxShadow: '0 0 0 25px var(--gray-1)'}} alt=":("/>
      <Title style={{marginBottom: "5px"}}> {props.title} </Title>
      <SubTitle> 
        {props.subtitle} If there are any questions, please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.</SubTitle>
    </>);
}

export function JustSubmittedComponent(props) {
  return (
    <>
      <PreTitle> {props.firstName} {props.lastName} </PreTitle>
      <Title style={{marginBottom: "5px"}}> 
        {props.type === 'participant' && 'Participants Submitted'} 
        {props.type === 'observer' && 'Answers Submitted'} 
      </Title>
      <SubTitle> 
        {/* TODO missing space between "shortly. and If there" */}
        {props.label === 'participant' && 'Thank you, your responses have been recorded. They will be contacted shortly. '}
        {props.label !== 'observer' && `Thank you, your feedback has been recorded. Your responses will be included with those from all others, such that your individual responses to each question can be anonymous. `} 
        If there are any problems please reach out to {props.supportName} at <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a> for next steps.
      </SubTitle>
    </>);
}

export function AlreadySubmittedComponent(props) {
  return (
    <>
      <PreTitle> {props.firstName} {props.lastName} </PreTitle>
      <Title style={{marginBottom: "5px"}}> You've already responded </Title>
      <SubTitle> 
        {props.type === 'participant' && `You have now provided all the names and contact details for those to give you feedback. 
        They will be contacted shortly. `}
        If there are any problems please reach out to {props.supportName} at <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a> for next steps.
      </SubTitle>
    </>);
}

export function TooLateComponent(props) {
  return (
    <>
      <PreTitle> {props.firstName} {props.lastName} </PreTitle>
      <Title style={{marginBottom: "5px"}}> Deadline has passed </Title>
      <SubTitle> 
        {props.type === 'participant' && 
        <>
          Unfortunately the deadline to select feedback observers have passed. 
          Please reach out to {props.supportName} at <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a> for next steps.
        </>}</SubTitle>
    </>);
}

export const Card = styled.div`
  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.06);
  height: 75px;
  display: grid;
  align-items: center;
  justify-items: center;   
`

export  const CardBody = styled.div`
  padding: 0.5rem;
`

export const CardTitle = styled.div`
  font-size: calc(1.275rem + .2vw);
  text-align: center;
`

export const CardSubtitle = styled.div`
  color: #626976;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  text-align: center;
`

export const ProjectCard = styled.div`
  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.16);
  padding: 1rem;
`

export const SplashContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 0 20px;
  align-items: center;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

export const LeftContent = styled.div`
  // font-family: Futura-Medium, sans-serif;
  justify-self: center;
  max-width: 350px;

  h2 {
    margin: 0 auto;
  }

  p {
    font-size: 0.75rem;
    line-height: var(--font-lineheight-4);
  }

  span {
    color: var(--green-8);
    font-size: 1rem;
  }

  li {
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
  }
`

export const RightContent = styled.div`
  max-width: 350px;
  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98, 105, 118, .16);
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;

  textarea {
    height: 5rem;
  }
`

export const SplashSubmitButton = styled(CTAButton)`
  background: var(--blue-7);

  :hover {
    background: var(--blue-9);
  }
`