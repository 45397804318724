import styled from "styled-components";
import {DayContainer} from './Day';

export const HeaderContainer = styled(DayContainer)`
  background: var(--calender-header-color);
  position: relative;
`
export const Title = styled.div`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  font-size: calc(var(--size) * 0.5);
  transform: translate(-50%, -50%);
  color: var(--calender-header-subtext-color);
`;


export default function HeaderDay(props) {
  return (
    <HeaderContainer>
    	<Title> {props.title} </Title>
    </HeaderContainer>
  );
}
