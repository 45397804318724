import {PreTitle, Title, SubTitle, PageContainer, ErrorMessageDiv, SubmitButton} from "../UI.js";
import { Field, FieldArray, ErrorMessage } from 'formik';
import { Formik, Form } from 'formik';
import {AnswerSchema} from '../../util/SchemaUtils';
import styled from "styled-components";

const Question = styled.div`
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.4;
  font-weight: 600;
  color: var(--gray-7);
  display: flex;
  align-items: center;
`
const AnswerDiv = styled.div`
  margin-right: 15px;
  
  textarea {
    display: flex;
    align-items: start;
    margin-left: 15px;
    margin-top: 10px;
    width: min(100%, 620px);
    outline: none;
    border: 1px solid var(--gray-3);
    resize: hidden; 
  }
`;

const ShortAnswerDiv = styled(AnswerDiv)`
  textarea {
    height: 1.5rem;
  }
  @media (max-width: 620px) {
    textarea {
      height: 3rem;
    }
  }
`

const ParagraphAnswerDiv = styled(AnswerDiv)`
  textarea {
    height: 3rem;
  }
  @media (max-width: 620px) {
    textarea {
      height: 5rem;
    }
  }
`

const TitleDiv = styled.div`
  @media (max-width: 740px) {
    margin-left: 1rem;
  }
`

// TODO mobile friendly
export default function AnswerEntryContent(props) {
  return (
    <div className="center">
      <TitleDiv>
        <PreTitle> {props.observer.firstName} {props.observer.lastName} </PreTitle>
        <Title> Give Feedback to <b>{props.participant.firstName} {props.participant.lastName}</b></Title>
        <SubTitle> Please fill out the form below.
          If you have any issues please reach out to <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a>.</SubTitle>
      </TitleDiv>
      <Formik key="observers" initialValues={props.initialValues} validationSchema={AnswerSchema}
      onSubmit={async _values => props.submitForm(_values)}>
        {(_props) =>
      <Form style={{display: 'grid', justifyContent: 'center'}}>
        <FieldArray name='answers'
          render={arrayHelpers => (
            <>
            {_props.values.answers && _props.values.answers.length > 0 && 
            _props.values.answers.map((answer, index) => (
              <PageContainer key={index}>
                <Question>{index+1}. {answer.question}</Question>
                {answer.type === 'paragraph' && 
                  <ParagraphAnswerDiv lines={3}>
                    <Field name={`answers.${index}.answer`} as="textarea"/>
                  </ParagraphAnswerDiv>
                }
                {answer.type === 'short-answer' && 
                  <ShortAnswerDiv lines={3}>
                    <Field name={`answers.${index}.answer`} as="textarea"/>
                 </ShortAnswerDiv>
                }
                <ErrorMessageDiv><ErrorMessage name={`answers.${index}.answer`}/></ErrorMessageDiv>
              </PageContainer>))}
            </>)}
      /> 
      <SubmitButton type="submit" style={{marginTop: "25px"}}>Submit</SubmitButton>
    </Form>}
  </Formik>
  </div>

 );
}