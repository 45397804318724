export function lazy(asyncCallback) {
  return () => {
    asyncCallback().catch((err) => console.error(err));
  };
}

export const defaultModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -55%)',
    maxWidth: '400px',
    maxHeight: '80vh',
    minWidth: '250px',
  }, overlay: {
    zIndex: '2'
  }
};