import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  display: block;
  margin: 8px;

  * {
    font-family: Arial, serif;
    font-size: 14px;
    color: #595959;
    line-height: 1.5;
  }

  h2 {
    font-size: 26px;
    color: #000;
  }

  h3 {
    font-size: 19px;
    color: #000;
  }

  h4 {
    font-size: 17px;
    color: #000;
  }

  a {
    color: #3030F1;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .grey {
    color: rgb(127, 127, 127)
  }

  .no-list-style {
    list-style: none;
  }
`
export default function PrivacyPage() {
    return (
        <PrivacyContainer>

            <div id="privacynotice">
                <h2>PRIVACY NOTICE</h2>
                <p className="bold grey">Last updated November 01, 2022 </p>
                <div>This privacy notice for Mini 360 ('<b>Company</b>', '<b>we</b>', '<b>us</b>', or '<b>our</b>',),
                    describes how
                    and why we might collect, store, use, and/or share ('<b>process</b>') your information when you use
                    our services
                    ('Services'), such as when you:
                </div>
                <ul>
                    <li>Visit our website at <a href="https://mini-360.com">https://mini-360.com</a>, <a
                        href="https://yourfeedbackisrequested.com">https://yourfeedbackisrequested.com</a> or any
                        website of our
                        that links to this privacy notice.
                    </li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <div><span className="bold grey">Questions or concerns?</span>
                    Reading this privacy notice will help you understand your privacy rights and choices. If you do not
                    agree with
                    our policies and practices, please do not use our Services.
                    If you still have any questions or concerns, please contact us at <a
                        href="mailto:support@mini-360.com">support@mini-360.com</a>.
                </div>
            </div>
            <div id="summary">
                <h3>SUMMARY OF KEY POINTS</h3>
                <p className="bold">This summary provides key points from our privacy notice, but you can find out more
                    details about
                    any of these topics by clicking the link following each key point or by using our table of contents
                    below to
                    find the section you are looking for.
                    You can also click <a href="#tableofcontents">here</a> to go directly to our table of contents.</p>
                <p><span className="bold">What personal information do we process?</span> When you visit, use, or
                    navigate our Services, we may process personal information depending on how
                    you interact
                    with Mini 360 and the Services, the choices you make, and the
                    products and features you use. Click <a href="#infocollect">here</a> to learn more.
                </p>
                <p><span className="bold">Do we process any sensitive personal information?</span> We do not process
                    sensitive personal information.
                </p>
                <p><span className="bold">Do we receive any information from third parties?</span> In most cases we
                    receive Personal Data from you. We might also obtain Personal Data from third
                    parties, if the
                    applicable national law allows us to do so. We will treat this Personal Data according to this
                    Privacy
                    Statement, plus any additional restrictions imposed by the third party that provided us with it or
                    the
                    applicable national law.
                    These third-party sources include Mini 360's business dealings with your employer (for details,
                    please see <a href="#contract">"Contract Performance"</a> subsection of Section B below)
                    or third parties you directed to share your Personal Data with us (e.g. in case of an event where
                    you permit the
                    host of the event to share your registration data with us).
                </p>
                <p><span className="bold">How do we process your information?</span> We process your information to
                    provide, improve, and administer our Services, communicate with you,
                    for security
                    and fraud prevention, and to comply with law. We may also process your information for other
                    purposes with your
                    consent.
                    We process your information only when we have a valid legal reason to do so. Click <a
                        href="#infouse">here</a> to learn more.
                </p>
                <p><span
                    className="bold">In what situations and with which parties do we share personal information?</span> We
                    may share information in specific situations and with specific third parties. Click <a
                        href="#whoshare">here</a> to learn more.
                </p>
                <p><span className="bold">How do we keep your information safe?</span> We have organisational and
                    technical processes and procedures in place to protect your personal
                    information.
                    However, no electronic transmission over the internet or information storage technology can be
                    guaranteed to be
                    100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised
                    third parties
                    will not be able to defeat our security and improperly collect, access, steal, or modify your
                    information. Click <a href="#infosafe">here</a> to learn more.
                </p>
                <p><span className="bold">What are your rights?</span> Depending on where you are located
                    geographically, the applicable privacy law may mean you have
                    certain rights
                    regarding your personal information. Click <a href="#privacyrights">here</a> to learn more.
                </p>
                <p><span className="bold">How do you exercise your rights?</span> The easiest way to exercise your
                    rights is by filling out our data subject request form available
                    here: <a
                        href="https://mini-360.com/deletemydata" target="_blank" rel="noreferrer">https://mini-360.com/deletemydata</a>, or by contacting
                    us. We
                    will consider and act upon any request in accordance with applicable data protection laws.
                </p>
                <p>Want to learn more about what Mini 360 does with any information we collect? Click <a
                    href="#whoshare">here</a>
                    to review the notice in full.</p>
            </div>

            <div id="tableofcontents">
                <h3>TABLE OF CONTENTS</h3>
                <ol>
                    <li><a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a></li>
                    <li><a href="#infouse"> HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                    <li><a href="#legalbases"> WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
                    </li>
                    <li><a href="#whoshare"> WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                    <li><a href="#inforetain"> HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                    <li><a href="#infosafe"> HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                    <li><a href="#infominors"> DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                    <li><a href="#privacyrights"> WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                    <li><a href="#DNT"> CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                    <li><a href="#caresidents"> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                    <li><a href="#updates"> DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                    <li><a href="#contact"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                    <li><a href="#request"> HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                </ol>
            </div>

            <div id="infocollect">
                <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
                <h4>Personal information you disclose to us</h4>
                <p><span className="bold">In short:</span> <i>We collect personal information that you provide to
                    us.</i>
                </p>
                <p> We collect personal information that you voluntarily provide to us when you express an interest in
                    obtaining
                    information about us or our products and Services, when you participate in activities on the
                    Services, or
                    otherwise when you contact us.
                </p>
                <p><span className="bold">Personal Information Provided by You</span>
                    The personal information that we collect depends on the context of your interactions with us and the
                    Services,
                    the choices you make, and the products and features you use. The personal information we collect may
                    include the
                    following:
                </p>
                <ul>
                    <li>Names</li>
                    <li>Email Addresses</li>
                </ul>
                <p><span className="bold">Sensitive Information.</span> We do not process sensitive information.</p>

                <h4>Personal information Indirectly disclosed to us</h4>
                <p><span className="bold">In short:</span> <i>We may also receive your data indirectly from institutions
                    and
                    companies
                    acting on your behalf.</i></p>
                <p>In most cases we receive Personal Data from you. We might also obtain Personal Data from third
                    parties,
                    if the
                    applicable national law allows us to do so. We will treat this Personal Data according to this
                    Privacy
                    Statement, plus any additional restrictions imposed by the third party that provided us with it or
                    the
                    applicable national law.
                    These third-party sources include Mini 360's business dealings with your employer (for details,
                    please see <a href="#contract">"Contract Performance”</a> subsection of Section B below)
                    or third parties you directed to share your Personal Data with us (e.g. in case of an event where
                    you
                    permit the
                    host of the event to share your registration data with us).</p>
            </div>

            <div id="infouse">
                <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
                <p><span className="bold">In short:</span> <i>We process your information to provide, improve, and
                    administer our
                    Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                    also
                    process
                    your information for other purposes with your consent.</i></p>
                <p><span className="bold">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>
                </p>
                <ul>
                    <li><span className="bold">To deliver and facilitate delivery of services to the user.</span> We may
                        process
                        your
                        information to provide you with the requested service.
                    </li>
                    <li><span className="bold">To save or protect an individual's vital interest.</span> We may process
                        your
                        information
                        when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                    </li>
                </ul>
            </div>

            <div id="legalbases">
                <h3>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h3>
                <p><span className="bold">In short:</span> <i>We only process your personal information when we believe
                    it
                    is
                    necessary
                    and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your
                    consent,
                    to
                    comply with laws, to provide you with services to enter into or fulfil our contractual obligations,
                    to
                    protect
                    your rights, or to fulfil our legitimate business interests.</i></p>
                <p><span
                    className="bold underline">If you are located in the EU or UK, this section applies to you.</span>
                </p>
                <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases
                    we rely
                    on
                    in order to process your personal information. As such, we may rely on the following legal bases to
                    process
                    your
                    personal information:
                </p>
                <p><span className="bold">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>
                </p>
                <ul>
                    <li><span className="bold">Consent.</span> We may process your information if you have given us
                        permission (i.e.
                        consent) to use your personal information for a specific purpose. You can withdraw your consent
                        at any
                        time.
                        Click <a href="#whoshare">here</a> to learn more.
                    </li>
                    <li id="contract"><span className="bold"> Contract Performance.</span> We may process your personal
                        information
                        when we
                        believe it is necessary to fulfil our contractual obligations to you, including providing our
                        Services
                        or at
                        your request prior to entering into a contract with you.
                    </li>
                    <li><span className="bold">Legal Obligations.</span> We may process your information where we
                        believe it
                        is
                        necessary for compliance with our legal obligations, such as to cooperate with a law enforcement
                        body or
                        regulatory agency, exercise or defend our legal rights, or disclose your information as evidence
                        in
                        litigation in which we are involved.
                    </li>
                    <li><span className="bold"> Vital Interests. </span> We may process your information where we
                        believe it
                        is
                        necessary to protect your vital interests or the vital interests of a third party, such as
                        situations
                        involving potential threats to the safety of any person.
                    </li>
                </ul>
            </div>

            <div id="whoshare">
                <h3>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
                <p><span className="bold">In short:</span> <i>We may share information in specific situations described
                    in
                    this
                    section
                    and/or with the following third parties.</i></p>
                <p>We may need to share your personal information in the following situations:
                </p>
                <ul>
                    <li><span className="bold">Business Transfers.</span> We may share or transfer your information in
                        connection
                        with,
                        or during negotiations of, any merger, sale of company assets, financing, or acquisition of all
                        or a
                        portion
                        of our business to another company.
                    </li>
                </ul>
            </div>

            <div id="inforetain">
                <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                <p><span className="bold">In short:</span> <i>We keep your information for as long as necessary to
                    fulfil
                    the
                    purposes
                    outlined in this privacy notice unless otherwise required by law.</i></p>
                <p>We will only keep your personal information for as long as it is necessary for the purposes set out
                    in this
                    privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                    accounting,
                    or
                    other legal requirements).
                </p>
                <p>When we have no ongoing legitimate business need to process your personal information, we will either
                    delete
                    or
                    anonymise such information, or, if this is not possible (for example, because your personal
                    information has
                    been
                    stored in backup archives), then we will securely store your personal information and isolate it
                    from any
                    further processing until deletion is possible.
                </p>
            </div>

            <div id="infosafe">
                <h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
                <p><span className="bold">In short:</span> <i>We aim to protect your personal information through a
                    system
                    of
                    organisational and technical security measures.</i></p>
                <p>We have implemented appropriate and reasonable technical and organisational security measures
                    designed to
                    protect
                    the security of any personal information we process. However, despite our safeguards and efforts to
                    secure
                    your
                    information, no electronic transmission over the Internet or information storage technology can be
                    guaranteed to
                    be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                    unauthorised third
                    parties will not be able to defeat our security and improperly collect, access, steal, or modify
                    your
                    information. Although we will do our best to protect your personal information, transmission of
                    personal
                    information to and from our Services is at your own risk. You should only access the Services within
                    a
                    secure
                    environment.
                </p>
            </div>

            <div id="infominors">
                <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                <p><span className="bold">In short:</span> <i>We do not knowingly collect data from or market to
                    children
                    under 18
                    years
                    of age.</i></p>
                <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the
                    Services, you
                    represent that you are at least 18 or that you are the parent or guardian of such a minor and
                    consent to
                    such
                    minor dependent’s use of the Services. If we learn that personal information from users less than 18
                    years
                    of
                    age has been collected, we will deactivate the account and take reasonable measures to promptly
                    delete such
                    data
                    from our records. If you become aware of any data we may have collected from children under age 18,
                    please
                    contact us at <a href="mailto:support@mini-360.com">support@mini-360.com</a>.
                </p>
            </div>

            <div id="privacyrights">
                <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                <p><span className="bold">In short:</span> <i>In some regions, such as the European Economic Area (EEA)
                    and
                    United
                    Kingdom (UK), you have rights that allow you greater access to and control over your personal
                    information.
                    You
                    may review, change, or terminate your account at any time.</i></p>
                <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws.
                    These
                    may
                    include the right (i) to request access and obtain a copy of your personal information, (ii) to
                    request
                    rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if
                    applicable,
                    to data portability. In certain circumstances, you may also have the right to object to the
                    processing of
                    your
                    personal information. You can make such a request by contacting us by using the contact details
                    provided in
                    the
                    section <a href="#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
                </p>
                <p>We will consider and act upon any request in accordance with applicable data protection laws</p>
                <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                    information,
                    you
                    also have the right to complain to your local data protection supervisory authority. You can find
                    their
                    contact
                    details here: <a
                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noreferrer">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                </p>
                <p>If you are located in Switzerland, the contact details for the data protection authorities are
                    available
                    here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noreferrer">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
                </p>
                <p><span className="bold underline">Withdrawing your consent:</span> If we are relying on your consent
                    to
                    process
                    your
                    personal information, which may be express and/or implied consent depending on the applicable law,
                    you have
                    the
                    right to withdraw your consent at any time. You can withdraw your consent at any time by contacting
                    us by
                    using
                    the contact details provided in the section <a href="#contact">'HOW CAN YOU CONTACT US ABOUT THIS
                        NOTICE?'</a> below.
                </p>
                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal
                    nor,
                    when
                    applicable law allows, will it affect the processing of your personal information conducted in
                    reliance on
                    lawful processing grounds other than consent.
                </p>
                <p>If you have questions or comments about your privacy rights, you may email us at <a
                    href="mailto:support@mini-360.com">support@mini-360.com</a>.
                </p>
            </div>

            <div id="DNT">
                <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    ('DNT')
                    feature or setting you can activate to signal your privacy preference not to have data about your
                    online
                    browsing activities monitored and collected. At this stage no uniform technology standard for
                    recognising
                    and
                    implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser
                    signals or
                    any
                    other mechanism that automatically communicates your choice not to be tracked online. If a standard
                    for
                    online
                    tracking is adopted that we must follow in the future, we will inform you about that practice in a
                    revised
                    version of this privacy notice.
                </p>
            </div>

            <div id="caresidents">
                <h3>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
                <p><span className="bold">In short:</span> <i>Yes, if you are a resident of California, you are granted
                    specific
                    rights
                    regarding access to your personal information.
                </i></p>
                <p>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who
                    are
                    California residents to request and obtain from us, once a year and free of charge, information
                    about
                    categories
                    of personal information (if any) we disclosed to third parties for direct marketing purposes and the
                    names
                    and
                    addresses of all third parties with which we shared personal information in the immediately
                    preceding
                    calendar
                    year. If you are a California resident and would like to make such a request, please submit your
                    request in
                    writing to us using the contact information provided below.
                </p>
                <p>If you are under 18 years of age, reside in California, and have a registered account with Services,
                    you have
                    the
                    right to request removal of unwanted data that you publicly post on the Services. To request removal
                    of such
                    data, please contact us using the contact information provided below and include the email address
                    associated
                    with your account and a statement that you reside in California. We will make sure the data is not
                    publicly
                    displayed on the Services, but please be aware that the data may not be completely or
                    comprehensively
                    removed
                    from all our systems (e.g. backups, etc.).
                </p>
                <h4>CCPA Privacy Notice</h4>
                <p>The California Code of Regulations defines a 'resident' as:</p>
                <ol className="no-list-style">
                    <li>(1) every individual who is in the State of California for other than a temporary or transitory
                        purpose
                        and
                    </li>
                    <li>(2) every individual who is domiciled in the State of California who is outside the State of
                        California
                        for
                        a temporary or transitory purpose
                    </li>
                </ol>
            </div>

            <div id="updates">
                <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                <p><span className="bold">In short:</span> <i>Yes, we will update this notice as necessary to stay
                    compliant
                    with
                    relevant laws.</i></p>
                <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated
                    'Revised' date and the updated version will be effective as soon as it is accessible. If we make
                    material
                    changes to this privacy notice, we may notify you either by prominently posting a notice of such
                    changes or
                    by
                    directly sending you a notification. We encourage you to review this privacy notice frequently to be
                    informed of
                    how we are protecting your information.
                </p>
            </div>

            <div id="contact">
                <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                <p>If you have questions or comments about this notice, you may email us at <a
                    href="mailto:support@mini-360.com">support@mini-360.com</a>.
                </p>
            </div>

            <div id="request">
                <h3>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
                <p>Based on the applicable laws of your country, you may have the right to request access to the
                    personal
                    information we collect from you, change that information, or delete it. To request to review,
                    update, or
                    delete
                    your personal information, please visit: <a
                        href="https://mini-360.com/deletemydata">https://mini-360.com/deletemydata</a>.
                </p>
            </div>

            <p id="disclaimer">This privacy policy was created using Termly's <a
                href="https://termly.io/products/privacy-policy-generator/" target="_blank" rel="noreferrer">Privacy Policy Generator</a>.</p>
        </PrivacyContainer>
    );
}
