import React, {useState} from "react";
import styled from "styled-components";
import SessionService from '../../api/SessionService';
import UserApi from '../../api/UserApi';
import { useNavigate } from "react-router-dom";
import { Title, OutlineButton, ErrorMessageDiv } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: var(--radius-1);
  margin-top: 3px;
  box-shadow: var(--shadow-1);
  border: 1px solid ${props => props.showError ? 'red' : 'rgba(0, 0, 0, 0.15)'}
`

export default function SignInModal(props) {
  let {setOpen, setSession, close} = props;
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [showError, setShowError] = useState(false);
  let navigate = useNavigate();
  
  const handleKeyPress = function(event) {
    if (event.keyCode === 13) {
      onSubmit().then(()=>console.log('Handled return key press'));
    } 
  };

  const navigateToHome = function () {
    navigate('/admin');
  }

  const onSubmit = async function() {
    if (!email || !password) {
      console.log('not submitting as email/password are missing');
      return
    }
    try {
      const response = await UserApi.signIn(email, password);
      if (!!response) {
        SessionService.createSession(response.user, response.session);
        setSession(true); 
        setOpen(false); 
        navigateToHome();
        setPassword('');
        setShowError(false);
        close();
      } else {
        setShowError(true);
      }
    } catch (e) {
      console.error('Failed to sign in', e);
      setShowError(true);
    }
  }
  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid var(--dark-color)", marginBottom: "10px"}}>
        <Title>Log in</Title>
        <FontAwesomeIcon className="icon-hover" icon={faCircleXmark} onClick={close}/>
      </div>
      <div style={{marginBottom: "10px"}}>
        <Input placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} onKeyDown={handleKeyPress} showError={showError}></Input>
        <Input type="password" placeholder="Password" value={password} onChange={event => setPassword(event.target.value)} onKeyDown={handleKeyPress} showError={showError}></Input>
        {showError && <ErrorMessageDiv>The username / password combination you entered was not recognized.</ErrorMessageDiv>}
      </div>
      <div  style={{display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "5px"}}>
        <OutlineButton onClick={close}>Dismiss</OutlineButton> 
        <OutlineButton onClick={onSubmit}>Submit</OutlineButton> 
      </div>
    </>
  );
}