import Api from "./Api";

const ProjectApi = {
  // findById: async function (id) {
  //   const response = await Api.get(`/projects/${id}`);
  //   return response.data;
  // },

    getAllProjects: async function (userId, includeArchived = false) {
        const response = await Api.get("/projects", {includeArchived: includeArchived, userId: userId});
        return response.data
    },

    create: async function (project) {
        const response = await Api.post(`/projects`, project);
        return response.data;
    },
    update: async function (project) {
        const response = await Api.put(`/projects`, project);
        return response.data;
    },
    activateProject: async function (projectId) {
        const response = await Api.get(`/projects/${projectId}/active`);
        console.log(response);
        return;
    },
    archiveProject: async function (projectId) {
        return await Api.get(`/projects/${projectId}/archive`);
    },
    generateReports: async function(projectId, participantsIds, downloadReports, email) {
        const response = await Api.post(`/projects/${projectId}/generateReports`, {participantsIds: participantsIds, downloadReports: downloadReports, email: email});
        return response.data;
    }
};

export default ProjectApi;
