import {FormContainer, FormInput, PreTitle, ProjectCard} from "../UI.js";
import {normalizeDate} from '../../util/DateUtils';
import {addDays} from 'date-fns';
import Calendar from '../calendar/Calendar';
import {DateSchema} from "../../util/SchemaUtils";
import {Form, Formik} from "formik";
import {useEffect} from "react";
import NotificationService from "../../api/NotificationService";

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export default function ProjectCreateDates(props) {
    const {setNextCallback, updateBodyField, setActive, sendObserverEmailImmediately, datesFormValues, setDatesFormValues} = props;

    useEffect(() => {
        setDatesFormValues(prevState => {
            return {...prevState, sendObserverEmailImmediately: sendObserverEmailImmediately};
        });
    }, [sendObserverEmailImmediately, setDatesFormValues])

    async function handleSubmit(_values) {
        console.log('~~datesForm', _values);
        try {
            updateBodyField('dates', _values);
            setDatesFormValues(_values)
            setActive(a => a + 1);
        } catch (e) {
            console.error('failed with error', e);
            // TODO maybe a mail-to?
            NotificationService.error(`Something went wrong. If issues persists, please reach out to ${SUPPORT_EMAIL}.`)
        }
    }

    return (

        <Formik key="dates" initialValues={datesFormValues} validationSchema={DateSchema}
                onSubmit={async _values => handleSubmit(_values)}>
            {props => {
                const {touched, errors, values, handleSubmit} = props;
                setNextCallback(handleSubmit);
                return (
                    <Form>
                        <ProjectCard>
                            <PreTitle style={{marginBottom: "5px"}}>Calendar</PreTitle>
                            <div className="grid-full-row center">
                                <Calendar project={values}/>
                            </div>
                        </ProjectCard>
                        <ProjectCard style={{marginBottom: "1.5rem"}}>
                            <PreTitle style={{marginBottom: "5px"}}>Dates</PreTitle>
                            <FormContainer>
                                <FormInput label={"Participant Initial:"} input={{
                                    name: "feedbackParticipantInitialDate",
                                    placeholder: normalizeDate(addDays(new Date(), 1)),
                                    errors: errors,
                                    touched: touched,

                                }}/>
                                <FormInput label={"Participant Reminder:"} input={{
                                    name: "feedbackParticipantReminderDate",
                                    placeholder: normalizeDate(addDays(new Date(), 2)),
                                    errors: errors,
                                    touched: touched
                                }}/>
                                <FormInput label={"Participant Deadline:"} input={{
                                    name: "feedbackParticipantDeadlineDate",
                                    placeholder: normalizeDate(addDays(new Date(), 3)),
                                    errors: errors,
                                    touched: touched
                                }}/>
                                {!datesFormValues.sendObserverEmailImmediately ?
                                    <FormInput label={"Observer Initial:"} input={{
                                        name: "feedbackObserverInitialDate",
                                        placeholder: normalizeDate(addDays(new Date(), 4)),
                                        errors: errors,
                                        touched: touched,
                                    }}/> : <div/>}
                                <FormInput label={"Observer Reminder:"} input={{
                                    name: "feedbackObserverReminderDate",
                                    placeholder: normalizeDate(addDays(new Date(), 5)),
                                    errors: errors,
                                    touched: touched
                                }}/>
                                <FormInput label={"Observer Deadline:"} input={{
                                    name: "feedbackObserverDeadlineDate",
                                    placeholder: normalizeDate(addDays(new Date(), 6)),
                                    errors: errors,
                                    touched: touched
                                }}/>
                            </FormContainer>
                        </ProjectCard>
                    </Form>)
            }}
        </Formik>
    );
}
