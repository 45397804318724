import styled from "styled-components";
import {AddNewRowButton, ErrorMessageDiv, InputTable, PreTitle, ProjectCard, TableIcon} from "../UI.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownLong, faArrowUpLong, faCirclePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {QuestionSchema} from "../../util/SchemaUtils";
import NotificationService from "../../api/NotificationService";

const VerticalIcon = styled.div`
  transition: all 0.3s ease 0s;
  font-size: 1.1rem;
  color: #1C6EA4;

  :hover {
    transform: translateY(-2px);
    box-shadow: 0 0 0 var(--size-1) hsl(210 10% 5%/25%);
  }
`;

const QuestionTable = styled(InputTable)`
  min-width: 500px;
`
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export default function ProjectCreateQuestions(props) {
    const {setNextCallback, updateBodyField, setActive, questionFormValues, setQuestionFormValues} = props;

    async function handleSubmit(_values) {
        console.log('questions', _values);
        try {
            updateBodyField('questions', _values.questions);
            setQuestionFormValues(_values)
            setActive(a => a+1);
        } catch (e) {
            console.error('Failed to save questions', e);
            // TODO maybe a mail-to?
            NotificationService.error(`Something went wrong. If issues persists, please reach out to ${SUPPORT_EMAIL}.`);
        }
    }

    return (
        <Formik key="questions" initialValues={questionFormValues} validationSchema={QuestionSchema}
                onSubmit={async _values => handleSubmit(_values)}>
            {(props) => { setNextCallback(props.handleSubmit);
                return (<Form><ProjectCard>
                    <PreTitle style={{marginBottom: "5px"}}>Questions</PreTitle>
                    <QuestionTable>
                        <thead>
                        <tr>
                            <th style={{width: "5px"}}></th>
                            <th style={{width: "5%"}}> #</th>
                            <th style={{width: "50%"}}> Question</th>
                            <th style={{width: "calc(40% - 5px)", minWidth: '120px'}}> Type</th>
                            <th style={{width: "5%"}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        <FieldArray name="questions"
                                    render={arrayHelpers => (
                                        <>
                                            {props.values.questions && props.values.questions.length > 0 ? (
                                                props.values.questions.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div style={{display: "flex"}}>
                                                                <VerticalIcon onClick={() => {
                                                                    if (index === 0) {
                                                                        return;
                                                                    }
                                                                    arrayHelpers.move(index, index - 1)
                                                                }} className="center">
                                                                    <Tippy content="Move question up"><span><FontAwesomeIcon
                                                                        icon={faArrowUpLong}/> </span></Tippy>
                                                                </VerticalIcon>
                                                                <VerticalIcon onClick={() => arrayHelpers.move(index, index + 1)}
                                                                              className="center">
                                                                    <Tippy content="Move question down"><span><FontAwesomeIcon
                                                                        icon={faArrowDownLong}/></span></Tippy>
                                                                </VerticalIcon>
                                                            </div>
                                                        </td>
                                                        <td style={{textAlign: "center"}}>{index + 1}</td>
                                                        <td>
                                                            <Field name={`questions.${index}.question`} as="textarea"
                                                                   placeholder="What is your favorite color?"/>
                                                            <ErrorMessageDiv><ErrorMessage
                                                                name={`questions.${index}.question`}/></ErrorMessageDiv>
                                                        </td>
                                                        <td>
                                                            <Field as="select" name={`questions.${index}.type`}>
                                                                <option value="short-answer">Short Answer</option>
                                                                <option value="paragraph">Paragraph</option>
                                                            </Field>
                                                            <ErrorMessageDiv><ErrorMessage
                                                                name={`questions.${index}.type`}/></ErrorMessageDiv>
                                                        </td>
                                                        <td>
                                                            <TableIcon onClick={() => arrayHelpers.remove(index)}
                                                                       className="red-icon-row center">
                                                                <Tippy content="Delete question"><span><FontAwesomeIcon
                                                                    icon={faTrashCan} className="small-icon"/> </span></Tippy>
                                                            </TableIcon>
                                                        </td>
                                                    </tr>))) : null}
                                            <tr>
                                                <td colSpan="5"
                                                    onClick={() => arrayHelpers.push({question: '', type: 'short-answer'})}>
                                                    <AddNewRowButton> <FontAwesomeIcon icon={faCirclePlus}
                                                                                       className="small-icon"/> Add new
                                                        question</AddNewRowButton></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5"><ErrorMessageDiv>{typeof props.errors.questions === 'string' &&
                                                    <ErrorMessage name={`questions`}/>}</ErrorMessageDiv></td>
                                            </tr>
                                        </>
                                    )}/>
                        </tbody>
                    </QuestionTable>
                </ProjectCard></Form>)}}
        </Formik>
    );
}
