import Api from "./Api";

const ObserverApi = {
  findById: async function (id, includeQuestions) {
    const response = await Api.get(`/projects/participants/observers/${id}`, {includeQuestions: includeQuestions});
    return response.data;
  },

  saveObserversForParticipants: async function (participantId, observers) {
    const response = await Api.post(`/projects/participants/${participantId}/observers`, observers);
    return response.data;
  },

  saveAnswersForObservers: async function (observerId, answers) {
    const response = await Api.post(`/projects/participants/observers/${observerId}/answers`, answers);
    return response.data;
  }
};

export default ObserverApi;
