import React, {useState} from "react";
import styled from "styled-components";
import logo from '../assets/logo-512.png';
import { Link, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import SessionService from '../api/SessionService';
import { useNavigate, NavLink } from "react-router-dom";
import SignInModal from "./modal/SignInModal";
import {defaultModalStyles} from '../util/Utils';

const MainBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  width: 99vw;
  max-width: 99vw;
  margin-bottom: 20px;
`;

const NavItems = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
`;

const NavAEl = styled.a`
  color: var(--gray-6);
  text-decoration: none;
  padding: 1.5rem;
  font-size: 1.6rem;

  :hover {
    color: var(--grey-7);
  }
`;

const CustomNavLink = styled(NavLink)`
  color: var(--gray-6);
  text-decoration: none;
  padding: 1.5rem;
  font-size: 1.6rem;

  :hover {
    color: var(--grey-7);
  }
`

const Logo = styled.img`
  padding: 0.5rem 0;
  font-size: 1.3rem;
`;

export default function Header() {
  const location = useLocation();

  let [isOpen, setOpen] = useState(false);
  let [hasSession, setSession] = useState(SessionService.isSessionValid);
  let navigate = useNavigate();

  const close = function() {
    setOpen(false);   
  }
  const navigateToHome = function () {
    navigate('/');
  }
  return (
    <MainBar>
      <Link to="/">
        <Logo src={logo} alt="Mini 360" height={60} style={{marginLeft: '5px'}} />
      </Link>
      <NavItems>
        {SessionService.isSessionValid() && 
        <>
        <CustomNavLink to='/admin' className={location.pathname === '/details' ? 'active' : ''}> Summary </CustomNavLink>
        <CustomNavLink to='/create'> Create </CustomNavLink>
        </>}
        <SignInButton setOpen={setOpen} setSession={setSession} hasSession={hasSession} navigateToHome={navigateToHome}/>
      </NavItems>
      <Modal isOpen={isOpen} style={defaultModalStyles} ariaHideApp={false}>
        <SignInModal setSession={setSession} setOpen={setOpen} close={close}/>
      </Modal>
    </MainBar>
  );
}

function SignInButton(props) {
  const onClick = function() {
    if (props.hasSession) {
      SessionService.invalidateSession();
      props.setSession(false);
      props.navigateToHome()
    } else {
      props.setOpen(true);
    }
  }

  return<NavAEl onClick={onClick} style={{justifySelf: 'end', marginLeft: 'auto'}}>{props.hasSession ? 'Log Out' : 'Sign In'}</NavAEl>
}