import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglass2, faClipboardCheck} from "@fortawesome/free-solid-svg-icons";
import {
    PageContainer,
    Title,
    Title2,
    SubTitle2,
    PreTitle,
    InputTable,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    OutlineButton
} from "../component/UI.js"
import Calendar from '../component/calendar/Calendar';
import {format, differenceInCalendarDays} from 'date-fns'
import NotificationService from '../api/NotificationService.js';
import SessionService from '../api/SessionService';
import {defaultModalStyles} from '../util/Utils';
import GenerateReportsModal from '../component/modal/GenerateReportsModal';

const classNames = require('classnames');

const ProjectCard = styled.div`
  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.16);
`

const SummaryTable = styled(InputTable)`
  th, td {
    border-left: 1px solid var(--dark-color);
    border-right: 1px solid var(--dark-color);
  }
  th {
    font-size: 0.8rem;
    text-align: center;
  }
  td {
    font-size: 0.75rem;
    padding: 0.25rem;

    span {
      display: block;
      padding: 0.25rem;
    }
  }
  max-width: 500px;

`

const TableIcon = styled(FontAwesomeIcon)`
  color: var(--secondary-color);
`;

export default function ProjectDetailsPage(props) {
    let {navigateToHome} = props;
    const {state} = useLocation();
    let [projects, setProjects] = useState([]);
    let [observers, setObservers] = useState([]);
    let [selectedRows, setSelectedRows] = useState([]);
    let [selectedParticipants, setSelectedParticipants] = useState([]);
    let [isOpen, setOpen] = useState(false);

    const close = function () {
        setOpen(false);
    }

    let navigate = useNavigate();

    useEffect(() => {
        if (!SessionService.isSessionValid()) {
            navigate('/', {loggedOut: true});
        }
    }, [navigate]);

    useEffect(() => {
        if (!state || !state.projects || state.projects.length === 0) {
            return navigateToHome();
        }

        setProjects(state.projects);
        let _observers = [];
        for (let i = 0; i < state.projects.length; i++) {
            _observers.push([]);
            for (let j = 0; j < state.projects[i].participants.length; j++) {
                let complete = true;
                for (let k = 0; k < state.projects[i].participants[j].observers.length; k++) {
                    let observer = state.projects[i].participants[j].observers[k];
                    _observers[i].push({
                        participantName: `${state.projects[i].participants[j].firstName} ${state.projects[i].participants[j].lastName}`,
                        name: `${observer.firstName} ${observer.lastName}`,
                        email: observer.email,
                        complete: !!observer.answers ? 'Complete' : 'Incomplete'
                    });
                    if (complete && !observer.answers) {
                        complete = false;
                    }
                }
                state.projects[i].participants[j].complete = complete;
            }
            _observers[i].sort((o1, o2) => o2.complete < o1.complete ? -1 : 1);
        }
        setSelectedRows([...Array(state.projects.length)].map(() => []));
        setSelectedParticipants([...Array(state.projects.length)].map(() => []));
        setObservers(_observers);
    }, [navigateToHome]);

    const selectRow = function (projectIndex, participantIndex) {
        const indexOf = selectedRows[projectIndex].indexOf(participantIndex);
        if (indexOf >= 0) {
            selectedRows[projectIndex].splice(indexOf, 1);
            selectedParticipants[projectIndex].splice(indexOf, 1);
            setSelectedRows([...selectedRows]);
            setSelectedParticipants([...selectedParticipants])
        } else {
            selectedRows[projectIndex].push(participantIndex);
            setSelectedRows([...selectedRows]);
            selectedParticipants[projectIndex].push(projects[projectIndex].participants[participantIndex].id);
            setSelectedParticipants([...selectedParticipants]);
        }
    }

    return (
        <div style={{width: "clamp(620px, 90vw, 100vw)", margin: "0 auto"}}>
            {projects.map((project, projectIndex) => (
                <PageContainer key={`details-${projectIndex}`} style={{marginBottom: "3rem"}}>
                    <CardBody>
                        <div style={{marginBottom: "1.5rem"}}>
                            <PreTitle>Project</PreTitle>
                            <Title>{project.projectName}</Title>
                            <SubTitle2
                                style={{marginBottom: "0.1rem"}}> {formatDate(project.feedbackParticipantInitialDate)} - {formatDate(project.feedbackObserverDeadlineDate)}</SubTitle2>
                            <SubTitle2 style={{marginBottom: "1.5rem", paddingLeft: "0.5rem"}}><i>Created
                                by {project.createdBy.name}</i></SubTitle2>
                            <div style={{
                                gridColumn: "1/-1",
                                display: "grid",
                                gridTemplateColumns: "repeat(4, 1fr)",
                                gridGap: "1rem"
                            }}>
                                <Calendar project={project}/>
                                <Card>
                                    <CardBody
                                        className={differenceInCalendarDays(new Date(project.feedbackObserverDeadlineDate), new Date()) < 0 ? 'text-green' : differenceInCalendarDays(new Date(project.feedbackObserverDeadlineDate), new Date()) < 7 ? 'text-yellow' : ''}>
                                        <CardTitle>{differenceInCalendarDays(new Date(project.feedbackObserverDeadlineDate), new Date()) >= 0 ? differenceInCalendarDays(new Date(project.feedbackObserverDeadlineDate), new Date()) : 'Project Complete'}</CardTitle>
                                        {differenceInCalendarDays(new Date(project.feedbackObserverDeadlineDate), new Date()) >= 0 &&
                                            <CardSubtitle> Days Remaining</CardSubtitle>}
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle>{project.reportsComplete}</CardTitle>
                                        <CardSubtitle>Reports Complete</CardSubtitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle
                                            className={project.participantsPending > 0 ? 'text-yellow' : ''}>{project.participantsPending}</CardTitle>
                                        <CardSubtitle>Pending Participants</CardSubtitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle
                                            className={project.observersPending > 0 ? 'text-yellow' : ''}>{project.observersPending}</CardTitle>
                                        <CardSubtitle>Pending Observers</CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <ProjectCard style={{marginBottom: "1.5rem"}}>
                            <div style={{marginTop: "5px", marginLeft: "10px"}}>
                                <PreTitle> Summary </PreTitle>
                                <Title2>Participants</Title2>
                            </div>
                            <div style={{
                                marginTop: "5px",
                                display: "grid",
                                justifyContent: "center",
                                marginBottom: "1rem"
                            }}>
                                {projects[projectIndex].participants.length > 0 ? <><SummaryTable>
                                    <thead>
                                    <tr style={{borderBottom: 0}}>
                                        <th colSpan={2} rowSpan={2}>Participant Name</th>
                                        <th rowSpan={2}>Email</th>
                                        <th colSpan={3}>Observers</th>
                                        <th rowSpan={2}>Status</th>
                                    </tr>
                                    <tr>
                                        <th style={{width: "50px", borderLeft: 0, borderRight: 0}}>Total</th>
                                        <th style={{width: "50px", borderLeft: 0, borderRight: 0}}><Tippy
                                            content="Complete"><span><TableIcon icon={faClipboardCheck}/></span></Tippy>
                                        </th>
                                        <th style={{width: "50px", borderLeft: 0, borderRight: 0}}><Tippy
                                            content="Pending"><span><TableIcon icon={faHourglass2}/></span></Tippy></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {projects[projectIndex].participants.map((participant, j) => (
                                        <tr key={`r-${projectIndex}-${j}`} className={classNames(
                                            {'Incomplete': participant.observers.length !== numberOfCompleteObserversForParticipant(participant)},
                                            {'Incomplete': participant.observers.length === 0},
                                            {'past-due': participant.observers.length === 0 && projects[projectIndex].pastDue},
                                            {'selected': selectedRows[projectIndex].includes(j)})}
                                            onClick={() => selectRow(projectIndex, j)}>
                                            <td style={{width: "30px"}}><input type="checkbox"
                                                                               checked={selectedRows[projectIndex].includes(j)}
                                                                               onChange={() => {
                                                                               }}></input></td>
                                            <td>{participant.firstName} {participant.lastName}</td>
                                            <td>{participant.email}</td>
                                            <td style={{textAlign: "center"}}> {participant.observers.length} </td>
                                            <td style={{textAlign: "center"}}> {numberOfCompleteObserversForParticipant(participant)} </td>
                                            <td style={{textAlign: "center"}}> {participant.observers.length - numberOfCompleteObserversForParticipant(participant)} </td>
                                            <td>{participant.observers.length === 0 ? 'No Observers Chosen' : !participant.complete ? 'Observers Pending' : 'Complete'}</td>
                                        </tr>))}
                                    </tbody>
                                </SummaryTable>
                                    <ButtonRow projects={projects} selectedParticipants={selectedParticipants}
                                               selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                                               setSelectedParticipants={setSelectedParticipants}
                                               projectIndex={projectIndex} setOpen={setOpen}/>
                                </> : <h3> No participants selected yet </h3>}
                            </div>
                        </ProjectCard>
                        <ProjectCard style={{marginTop: "1rem"}}>
                            <div style={{marginTop: "5px", marginLeft: "10px"}}>
                                <PreTitle> Summary </PreTitle>
                                <Title2>Observers</Title2>
                            </div>
                            <div style={{
                                marginTop: "5px",
                                display: "grid",
                                justifyContent: "center",
                                marginBottom: "1rem"
                            }}>
                                {observers[projectIndex].length > 0 ?
                                    <SummaryTable>
                                        <thead>
                                        <tr style={{borderBottom: 0}}>
                                            <th>Participant Name</th>
                                            <th>Observer Name</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {observers[projectIndex].map((observer, j) => (
                                            <tr key={`o-${projectIndex}-${j}`} className={observer.complete}>
                                                <td>{observer.participantName}</td>
                                                <td>{observer.name}</td>
                                                <td>{observer.email}</td>
                                                <td>{observer.complete} </td>
                                            </tr>))}
                                        </tbody>
                                    </SummaryTable> : <h3> No observers selected yet </h3>}
                            </div>
                        </ProjectCard>
                    </CardBody>
                    <Modal isOpen={isOpen} style={defaultModalStyles} ariaHideApp={false}>
                        <GenerateReportsModal close={close} projectId={projects[projectIndex].id}
                                              selectedParticipants={selectedParticipants[projectIndex]}
                                              setSelectedRows={setSelectedRows}
                                              setSelectedParticipants={setSelectedParticipants}
                                              supportEmail={project.supportEmail}
                                              defaultEmail={SessionService.getSession().user.email}/>
                    </Modal>
                </PageContainer>))}
        </div>
    );
}

function ButtonRow(props) {
    const {projectIndex, selectedParticipants, setOpen} = props;

    const generateReports = function () {
        if (selectedParticipants[projectIndex].length === 0) {
            return NotificationService.warning('You must select at least one project to generate');
        }
        setOpen(true);
    }

    return (
        <div style={{display: "flex", gap: "1rem", marginTop: "10px"}}>
            <OutlineButton onClick={() => generateReports()}>Generate Reports</OutlineButton>
        </div>)
}

function numberOfCompleteObserversForParticipant(participant) {
    let count = 0;
    for (let i = 0; i < participant.observers.length; i++) {
        if (!!participant.observers[i].answers) {
            count++;
        }
    }
    return count;
}

function formatDate(date) {
    date = new Date(date);
    return format(date, 'MMMM do yyyy');
}