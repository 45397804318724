import styled from "styled-components";
import React from 'react';
import Calendar from '../calendar/Calendar';
import {PreTitle} from "../UI.js";
import ProjectCustomizationSummary from './ProjectCustomizationSummary';

const SummaryTable = styled.table`
  width: clamp(540px, 70vw, 90vw);
  max-width: 580px;

  background: var(--secondary-color);
  text-align: left;
  border-collapse: collapse;
  box-shadow: var(--shadow-2);
  thead, tfoot {
    background: var(--gray-5);
  }
  th {
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
  }
  th,td {
    font-size: 0.8rem;
    padding: .8rem;
  }
  tr {
    border-bottom: 1px solid var(--gray-4);
    min-height: 3rem;
  }
`

const ProjectCard = styled.div`
  width: clamp(540px, 80vw, 90vw);
  max-width: 600px;

  background: var(--primary-color);
  box-shadow: var(--shadow-2);
  border-radius: 4px;
  border: 1px solid rgba(98,105,118,.16);
  padding: 1rem;
  display: grid;
  justify-content: center;
`

const questionTypeLookUp = function (type) {
  if (type === 'short-answer') {
    return 'Short Answer';
  }
  return type;
}

export default function ProjectCreateSummary(props) {
  const {formValues} = props;
  function showParticipants() {
    return formValues.participants.map((participant, index) => <tr key={`participant${index}`}><td>{index+1}</td><td>{participant.firstName}</td><td>{participant.lastName}</td><td>{participant.email}</td></tr>);
  }

  function showQuestions() {
    return formValues.questions.map((question, index) => <tr key={`question{index}`}><td>{index+1}</td><td>{question.question}</td><td>{questionTypeLookUp(question.type)}</td></tr>);
  }

  return (
    <>
      <PreTitle>Summary</PreTitle>
      <div style={{display: "flex", flexDirection:"column", gap: "15px", justifyContent:"center", alignItems: "center"}}>
        <ProjectCard>
          <PreTitle style={{marginBottom: "5px"}}>Dates</PreTitle>
          <Calendar project={formValues.dates}/>
          </ProjectCard>
        <ProjectCard>
          <PreTitle style={{marginBottom: "5px"}}>Participants</PreTitle>
          <SummaryTable>
            <thead>
              <tr>
                <th style={{width: "10%"}}> # </th>
                <th style={{width: "25%"}}> First Name </th>
                <th style={{width: "25%"}}> Last Name </th>
                <th style={{width: "40%"}}> Email </th>
              </tr>
            </thead>
            <tbody>
              {showParticipants()}
            </tbody>
          </SummaryTable>
        </ProjectCard>
        <ProjectCard>
          <PreTitle style={{marginBottom: "5px"}}>Questions</PreTitle>
          <SummaryTable>
            <thead>
              <tr>
                <th style={{width: "10%"}}> # </th>
                <th style={{width: "50%"}}> Question </th>
                <th style={{width: "40%"}}> Type </th>
              </tr>
            </thead>
            <tbody>
              {showQuestions()}
            </tbody>
          </SummaryTable>
        </ProjectCard>
        <ProjectCustomizationSummary formValues={formValues.project}></ProjectCustomizationSummary>
      </div>
    </>
  );
}
