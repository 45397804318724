import styled from "styled-components";
import react, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Title, PreTitle, PageContainer, CardContent, CTAButton, SubmitButton} from "../component/UI.js";
import NotificationService from '../api/NotificationService';
import ProjectApi from "../api/ProjectApi";
import SessionService from '../api/SessionService';
import {DateSchema, ParticipantSchema, QuestionSchema, ProjectSetupSchema} from '../util/SchemaUtils';
import ProjectCreateCustomization from "../component/projects/ProjectCreateCustomization";
import ProjectCreateDates from "../component/projects/ProjectCreateDates";
import ProjectCreateQuestions from "../component/projects/ProjectCreateQuestions";
import ProjectCreateParticipants from "../component/projects/ProjectCreateParticipants";
import ProjectCreateSummary from "../component/projects/ProjectCreateSummary";
import {createEmptyContact} from "../component/projects/ContactEntryForm";

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const FormHeader = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px;
`;

const CircleTitle = styled.div`
  font-size: 0.8rem;
  align-self: center;
  color: var(--gray-8)
`;

const HorizontalLine = styled.span`
  width: 100%;
  margin: 5px;
  border-bottom: 1px solid var(--gray-7);
  transform: translateY(-50%);

  @media (max-width: 620px) {
    width: 3rem;
  }
`;

const CircleNumber = styled.div`
  border-radius: 50%;
  background: var(--form-background);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  color: white;
`;

export default function ProjectCreatePage(props) {
    let [active, setActive] = useState(0);
    let [body, setBody] = useState({});
    let [session, _] = useState(SessionService.getSession());
    let navigate = useNavigate();

    let [datesFormValues, setDatesFormValues] = useState({
        feedbackParticipantInitialDate: '',
        feedbackParticipantReminderDate: '',
        feedbackParticipantDeadlineDate: '',
        feedbackObserverInitialDate: '',
        feedbackObserverReminderDate: '',
        feedbackObserverDeadlineDate: '',
        sendObserverEmailImmediately: false,
    });

    let [projectSetupFormValues, setProjectSetupFormValues] = useState({
        projectName: '',
        allowParticipantLateEntry: true,
        sendObserverEmailImmediately: false,
        minimumNumberOfObservers: 3,
        maximumNumberOfObservers: 50,
        projectLeadName: session.user.name,
        supportName: session.user.name,
        supportEmail: session.user.email,
        statusReportEmail: session.user.email,
        finalReportEmail: session.user.email,
        includeObserversInReport: true,
        leftLogoId: '',
        rightLogoId: '',
        feedbackReportPagePerQuestion: true
    });

    let [questionFormValues, setQuestionFormValues] = useState({
        questions: [
            { question: '',  type: 'short-answer' }
        ]
    });

    let [participantFormValues, setParticipantFormValues] = useState({
        participants: [
            createEmptyContact()
        ]
    });

    useEffect(() => {
        if (!SessionService.isSessionValid(session)) {
            return navigate('/', {state: {loggedOut: true}});
        }
        setBody(b => ({...b, userId: session.user.id}));
    }, [navigate, session]);

    let nextCallback;

    function setNextCallback(callback) {
        nextCallback = callback;
    }

    async function updateBodyField(field, value) {
        body[field] = value;
        console.log('body', body);
    }

    async function next() {
        await nextCallback();
    }

    async function movePage(target) {
        if (active > target) {
            console.log('moving backwards')
            return setActive(target);
        }


        // TODO figure out why it's not working
        // for (let i = 0; i < target; i++) {
        //   if (!pages[i].schema) {
        //     console.log('no schema)')
        //     continue;
        //   }
        //   if (!await pages[i].schema.isValid(body[pages[i].field] || [])) {
        //     console.log('body', body, 'field', pages[i].field)
        //     console.log(body[pages[i].field] || [])
        //     console.log('schema invalid)')
        //     return;
        //   }
        // }
        // return setActive(target);
    }

    async function createProject() {
        // TODO add a "save as draft button"
        body.project.userId = body.userId
        body.project = {...body.project, ...body.dates}
        console.log('about to save', body)
        try {
            await ProjectApi.create(body);
            NotificationService.success("Successfully saved project")
            props.navigateToHome();
        } catch (e) {
            console.error('Failed to save project', e);
            NotificationService.error(`Failed to save project. If issues persists please reach out to ${SUPPORT_EMAIL}.`)
        }
    }

    let pages = [
        {
            label: 'Project Setup',
            field: 'project',
            schema: ProjectSetupSchema,
            content: <ProjectCreateCustomization updateBodyField={updateBodyField} setNextCallback={setNextCallback} setActive={setActive} projectSetupFormValues={projectSetupFormValues} setProjectSetupFormValues={setProjectSetupFormValues}/>
        },
        {
            label: 'Dates',
            field: 'dates',
            schema: DateSchema,
            content: <ProjectCreateDates updateBodyField={updateBodyField} setNextCallback={setNextCallback} setActive={setActive} initialValues={body} sendObserverEmailImmediately={projectSetupFormValues.sendObserverEmailImmediately} datesFormValues={datesFormValues} setDatesFormValues={setDatesFormValues}/>
        },
        {
            label: 'Questions',
            field: 'questions',
            schema: QuestionSchema,
            content: <ProjectCreateQuestions updateBodyField={updateBodyField} setNextCallback={setNextCallback} setActive={setActive} initialValues={body} questionFormValues={questionFormValues} setQuestionFormValues={setQuestionFormValues}/>
        },
        {
            label: 'Participants',
            field: 'participants',
            schema: ParticipantSchema,
            content: <ProjectCreateParticipants updateBodyField={updateBodyField} setNextCallback={setNextCallback} setActive={setActive} initialValues={body} participantFormValues={participantFormValues} setParticipantFormValues={setParticipantFormValues}/>
        },
        {
            label: 'Confirmation',
            field: ['project', 'dates', 'questions', 'participants'],
            content: <ProjectCreateSummary formValues={body}/>
        }
    ]

    return (
        <div className="center">
            <PageContainer>
                <PreTitle>Create</PreTitle>
                <Title> Create a new project </Title>
                <FormHeader>
                    {pages.map((page, index) => <react.Fragment key={`page${index}`}>
                        <NavigationCircle key={`page${index}`} id={index} active={active} text={pages[index].label} onClick={async () => movePage(index)}/>
                        {index < pages.length && <HorizontalLine/>}
                    </react.Fragment>)}
                </FormHeader>
                <CardContent>
                    <div className="center">
                        {pages[active].content}
                    </div>
                    <LowerButtons active={active} setActive={setActive} createProject={createProject} next={next}/>
                </CardContent>
            </PageContainer>
        </div>
    );
}

const CircleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: center;
  gap: 5px;
  @media (max-width: 620px) {
    grid-template-columns: auto;
    gap: 1px;
  }
`;

function NavigationCircle(props) {
    const {id, active, text} = props;
    return (<CircleContainer onClick={props.onClick}>
        <CircleNumber
            className={`center ${id === active ? 'form-active' : ''} ${id < active ? 'form-complete' : ''}`}> {id < active ?
            <FontAwesomeIcon icon={faCheck}/> : id + 1} </CircleNumber>
        <CircleTitle> {text} </CircleTitle>
    </CircleContainer>)
}

function LowerButtons(props) {
    const {active, setActive, createProject, next} = props;
    return (<div className="m1 grid" style={{gridTemplateColumns: "1fr 1fr 1fr"}}>
        <div className="left ml-1">
            {active > 0 && <CTAButton type="submit" onClick={() => setActive(active - 1)}> Back </CTAButton>}
        </div>
        <div className="center">
            {active === 4 && <SubmitButton onClick={createProject}> Submit </SubmitButton>}
        </div>
        <div className="right mr-1">
            {active < 4 && <CTAButton type="submit" onClick={next}> Next </CTAButton>}
        </div>
    </div>)
}
